// we disable func-style, because we'd otherwise have to export const funky = function funky() ... :(

import { Breakpoint } from '../types';
import type { ComplexAttributeConverter, LitElement } from 'lit-element';

const COLOR_PREFIX = '--zui-color-';

interface MinMaxInterface {
  min: number;
  max: number;
}

const breakpoints: Record<Breakpoint, MinMaxInterface> = {
  [Breakpoint.XS]: {
    min: 0,
    max: 767,
  },
  [Breakpoint.S]: {
    min: 768,
    max: 1023,
  },
  [Breakpoint.M]: {
    min: 1024,
    max: 1279,
  },
  [Breakpoint.L]: {
    min: 1280,
    max: Infinity,
  },
};

/**
 * Checks if a string is not empty
 * a string is also empty when it contains only spaces
 *
 * @param {string | undefined} testString - The string which should be checked
 * @returns {boolean} whether the string is not empty
 * @private
 */
export function isStringNotEmpty(testString: string | undefined): boolean {
  // we are not empty, if the trimmed string has a length > 0
  if (testString) {
    return testString.trim().length > 0;
  } else {
    return false;
  }
}

/**
 * Checks if a string is empty
 * a string is also empty when it contains only spaces
 *
 * @param {string | undefined} testString - The string which should be checked
 * @returns {boolean} whether the string is empty
 * @private
 */
export function isStringEmpty(testString: string | undefined): boolean {
  return !isStringNotEmpty(testString);
}

/**
 * Checks if slot is not empty
 *
 * @param {HTMLSlotElement} slot the slot which gets checked
 * @returns {boolean} whether the slot is not empty
 * @private
 */
export function isSlotNotEmpty(slot: HTMLSlotElement): boolean {
  return slot.assignedNodes().length > 0;
}

/**
 * Checks if a slot is empty, i.e. has no assigned nodes
 *
 * @param {HTMLSlotElement} slot the slot which gets checked
 * @returns {boolean} whether the slot is empty
 * @private
 */
export function isSlotEmpty(slot: HTMLSlotElement): boolean {
  return !isSlotNotEmpty(slot);
}

/**
 * Returns the zui font, because font gets rendered differently by the browser than it gets set in the variable
 *
 * @param {string} fontClass class of the zui font
 * @returns {string} information of the zui font
 * @private
 */
export function getZuiFont(fontClass: string): string {
  // TODO: maybe should be refactored into own generate function
  const div = document.createElement('div');
  div.setAttribute('style', 'font: var(--zui-typography-' + fontClass + ');');
  document.documentElement.appendChild(div);
  const zuiFont = window.getComputedStyle(div).getPropertyValue('font');
  document.documentElement.removeChild(div);
  return zuiFont;
}

/**
 * Gets the rgb of a zui-color
 *
 * @param {string} colorname name of the zui color
 * @param {string} alpha optional alpha/opacity
 * @returns {string} rgb or when opacity is present the rgba of the zui color
 * @private
 */
export function getZuiColor(colorname: string, alpha?: string): string {
  const zuiColor = window.getComputedStyle(document.documentElement).getPropertyValue(COLOR_PREFIX + colorname);
  return hexToRgb(zuiColor, alpha);
}

/**
 * Gets the value of a css prpoerty from the given element
 *
 * @param {HTMLElement} element the element which
 * @param {string} prop the property
 * @returns {string} the value of the css property
 * @private
 */
export function getCssPropertyFromElement(element: HTMLElement, prop: string): string {
  const propertyValue = window.getComputedStyle(element).getPropertyValue(prop);
  return propertyValue;
}

/**
 * Converts a hex color to a rgba color
 *
 * @param {string} hex the hex color which should converted to rgb
 * @param {string} alpha optional alpha/opacity
 * @returns {string} the converted rgb color
 * @private
 */
export function hexToRgb(hex: string, alpha?: string): string {
  // something like -> https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  const color = hex.trim();
  const red = parseInt(color.slice(1, 3), 16);
  const green = parseInt(color.slice(3, 5), 16);
  const blue = parseInt(color.slice(5, 7), 16);

  return alpha ? `rgba(${red}, ${green}, ${blue}, ${alpha})` : `rgb(${red}, ${green}, ${blue})`;
}

/**
 * Returns the computed style for an element returned by a query from a shadow dom
 *
 * @param {Element} webelement the webelement in which shadow dom the query should search
 * @param {string} cssQuery the query for the search
 * @returns {CSSStyleDeclaration} the returned computed Style, it will be empty when the query didn't found an element
 * @private
 */
export function getComputedStyleForQueryInShadowDom(webelement: Element, cssQuery: string): CSSStyleDeclaration {
  const element = webelement.shadowRoot?.querySelector(cssQuery);
  if (element) {
    return window.getComputedStyle(element);
  } else {
    // this will return an empty CSSStyleDeclaration
    return window.getComputedStyle(document.createElement('p'));
  }
}

/**
 * Compares the value of a specific border-property of an element to an expected value
 *
 * @param {HTMLElement} element the element to check
 * @param {string} borderProperty the specific border-property to check
 * @param {string} value expected value
 * @returns {boolean} whether the value is like it was expected
 * @private
 */
export function hasElementBorderPropertyValue(element: HTMLElement, borderProperty: string, value: string): boolean {
  return ['top', 'right', 'bottom', 'left'].every(
    (borderPart) => value === getCssPropertyFromElement(element, `border-${borderPart}-${borderProperty}`)
  );
}

/**
 * @param {HTMLElement} element the element where to find the slots
 * @param {string} slotName the name of the slot to obtain nodes
 * @returns {HTMLElement[]} returns the slotted element
 * @private
 */
export function getSlottedElementsFromNamedSlot(element: HTMLElement, slotName: string): HTMLElement[] {
  return Array.from(
    // we simply assume being called only on elements with shadow DOM
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (element.shadowRoot!.querySelector(`slot[name=${slotName}]`) as HTMLSlotElement).assignedNodes()
  ).filter((node) => node instanceof HTMLElement) as HTMLElement[];
}

/**
 * propagates a value to a selection of childs found by query from an element
 *
 * @param slot that the predicate is used to propagate values for
 * @param predicate function that filters childs
 * @param property that should be set for child matching predicagte
 * @param value for property for matched child by predicate
 */
// TODO: come up with some SICK uber TS magic, that generates a matching type for property and value
export function propagateValueToSlotContentByPredicate(
  slot: HTMLSlotElement,
  predicate: <T extends HTMLElement>(childElement: T) => boolean,
  property: string,
  value: unknown
): void {
  Array.from(slot.assignedElements())
    .filter(predicate)
    // eslint-disable-next-line no-return-assign
    .forEach((contentElement) => (contentElement[property] = value));
}
/**
 * returns the serialized DOMContent as a string from a slot
 *
 * @param {HTMLSlotElement} slot whose content should be serialized
 * @returns {string|undefined} returns either a string if the slot contains something or undefined if it is empty
 */
export function serializeSlotContent(slot: HTMLSlotElement): string | undefined {
  if (slot.assignedNodes().length > 0) {
    // get a temporary node, for our serializing business
    const tempNode = document.createElement('span');
    slot.assignedNodes().forEach((node) => tempNode.append(node.cloneNode(true)));
    return tempNode.innerHTML;
  } else {
    return undefined;
  }
}

/**
 * @param {HTMLElement} element the element from what the shadowRoot should be queried
 * @param {string|undefined} slotName an optional slotName for the querySelector
 * @returns {HTMLSlotElement | null} returns either a HTMLSlotElement or null
 */
export function getSlotByName(element: HTMLElement, slotName?: string): HTMLSlotElement | null {
  // lets hope this gets only called on element with an actual shadowRoot
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return element.shadowRoot!.querySelector(`slot${slotName ? `[name="${slotName}"]` : ':not([name])'}`);
}

/**
 * @param {number} width the width that lies between a min and max of a Breakpoint
 * @returns {Breakpoint} returns a Breakpoint
 */
export function getBreakpointForWidth(width: number): Breakpoint {
  return Object.keys(breakpoints).find(
    (bp) => width >= breakpoints[bp].min && width <= breakpoints[bp].max
  ) as Breakpoint;
}

type MediaOperator = '<' | '>';
const mediaOperations: Record<MediaOperator, (a: MinMaxInterface, b: MinMaxInterface) => boolean> = {
  '<': (a, b) => a.max < b.min,
  '>': (a, b) => a.min > b.max,
};

/**
 * @param {Breakpoint} media media
 * @param {'<' | '>'} operator smaller / larger
 * @param {Breakpoint} breakpoint breakpoint
 * @returns {boolean} returns whether the given media is larger or smaller than the breakpoint or not
 */
export function compareMediaBreakpoint(media: Breakpoint, operator: MediaOperator, breakpoint: Breakpoint): boolean {
  const operation = mediaOperations[operator];
  return operation(breakpoints[media], breakpoints[breakpoint]);
}

// custom converters

/**
 * component attribute converter from string to boolean and vice versa
 */
export const booleanStringConverter = {
  fromAttribute: (value: string | null): boolean => value === 'true',
  toAttribute: (value: boolean): string | null => (value ? 'true' : 'false'),
};

/**
 * a factory for creating a custom lit element converter for strings which are declared as list
 * (separated by a given string) in attributes, but handled as array properties internally
 *
 * @param separator to be used to split and join items
 * @param mapperConverter is an inner converter, that is called after the string list has been unwrapped
 * @returns custom attribute converter
 */
export const getStringArrayConverter = <T>(
  separator = ' ',
  mapperConverter: Required<ComplexAttributeConverter> = {
    fromAttribute(value: string | null): unknown {
      return value;
    },
    toAttribute(value: unknown): unknown {
      return value;
    },
  }
): ComplexAttributeConverter<T[]> => ({
  fromAttribute: (value: string | null): T[] =>
    value === null || value.length === 0
      ? []
      : ((value.split(separator).map(mapperConverter.fromAttribute) as unknown) as T[]),
  toAttribute: (value: T[] | undefined): string =>
    value === undefined || value.length === 0 ? '' : value.map(mapperConverter.toAttribute).join(separator),
});

/**
 * component attribute converter from stringy number to number | undefined and vice versa
 */
export const numberUndefinedConverter = {
  fromAttribute: (value: string | null): number | undefined => (value === null ? undefined : Number(value)),
  toAttribute: (value: number | undefined): string | null => (value ? String(value) : null),
};

/**
 * component attribute converter from string to string | undefined and vice versa
 */
export const stringUndefinedConverter = {
  fromAttribute: (value: string | null): string | undefined => (value === null ? undefined : value),
  toAttribute: (value: string | undefined): string | null => (typeof value === 'string' ? value : null),
};

/**
 * wraps any passed converter and maps empty string in attribute to empty attribute
 * @param converterToBeWrapped
 * @returns ComplexAttributeConverter
 */
export const emptyStringToNullWrapperConverter = (
  converterToBeWrapped: Required<ComplexAttributeConverter>
): ComplexAttributeConverter => ({
  fromAttribute: (value: string | null): string | null =>
    // if empty string is passed map it internally to an empty = null attribute
    value?.length === 0
      ? (converterToBeWrapped.fromAttribute(null) as string | null)
      : (converterToBeWrapped.fromAttribute(value) as string | null),
  toAttribute: converterToBeWrapped.toAttribute,
});

/**
 * returns the background color of a given element
 *
 * @param {HTMLElement} element to be inspected
 * @returns {string} the background color as rgb string
 */
export const getComputedBackgroundColor = (element: Element): string =>
  window.getComputedStyle(element).getPropertyValue('background-color');

/**
 * returns the opacity of a given element
 *
 * @param {HTMLElement} element to be inspected
 * @returns {string} the opacity as string
 */
export const getComputedOpacity = (element: Element): string =>
  window.getComputedStyle(element).getPropertyValue('opacity');

/**
 * returns the clamped value between a given minimum and a maximum
 *
 * @param {number} min the minimum
 * @param {number} n the number to be clamped
 * @param {number} max the maximum
 * @returns {number} the clamped value
 */
export const clamp = (min: number, n: number, max: number): number => {
  return Math.max(min, Math.min(n, max));
};

/**
 * returns whether the passed value is defined, i.e. neither `null` nor `undefined`
 * @param value
 * @returns true, if defined otherwise false
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== null && value !== undefined;
}

/**
 * returns the next number dependent on the given direction and step
 * when the max is reached the min is returned; when the min is reached the max is returned
 *
 * @param {number} value current value
 * @param {number} min min value
 * @param {number} max max value
 * @param {number} step the value that is decreased or increased from current value
 * @param {number} direction decrease or increase value
 *
 * @returns {number} the decreased or increased value
 */
export const cycle = (
  value: number,
  min: number,
  max: number,
  step: number,
  direction: 'decrease' | 'increase'
): number => {
  switch (direction) {
    case 'decrease': {
      const nextStep = value - step;

      return nextStep >= min ? nextStep : max + 1 - Math.abs(min - nextStep);
    }
    case 'increase': {
      const nextStep = value + step;

      return nextStep <= max ? nextStep : min - 1 + Math.abs(nextStep - max);
    }
  }
};

// TODO: this must be moved into test.utils.ts after BuildFix
/**
 * this is a helper function, that allows to wait for a certain amount of invocations of a
 * method of an object by returning promises in a fluent API interface
 *
 * @param obj
 * @param methodName
 * @param initialCount
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function waitForInvocationsOfMethod(obj: unknown, methodName: string, initialCount = 1) {
  let times = initialCount;
  let resolveOnce, resolveTwice, resolveThrice;
  const oncePromise = new Promise((resolve) => (resolveOnce = resolve));
  const twicePromise = new Promise((resolve) => (resolveTwice = resolve));
  const thricePromise = new Promise((resolve) => (resolveThrice = resolve));

  const resolveOnTimes = () => {
    if (times >= 1) {
      resolveOnce();
    }
    if (times >= 2) {
      resolveTwice();
    }
    if (times >= 3) {
      resolveThrice();
    }
  };

  resolveOnTimes();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore too much meta
  const oldMethod = obj[methodName];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore too much meta
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  obj[methodName] = function (...args) {
    times++;
    const result = oldMethod.apply(obj, args);
    resolveOnTimes();
    return result;
  };
  return {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    once: async () => oncePromise,
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    twice: async () => twicePromise,
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    thrice: async () => thricePromise,
  };
}

/**
 * helper function to wait for a LitElement to have its render() function been called a certain amount of times
 *
 * @param elm LitElement to wait for
 * @param initialCount is the initial count the elm has already been rendered
 *
 * @returns a fluent API ( twice(), ...) that can be waited for
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function elementHasRendered(elm: LitElement, initialCount = 1) {
  return waitForInvocationsOfMethod(elm, 'render', initialCount);
}
