/* eslint-disable lit/no-template-bind */
import { ClientRectObject, VirtualElement } from '@popperjs/core/lib/popper-lite';
import { Placement } from '@popperjs/core/lib/enums';
import {
  css,
  customElement,
  LitElement,
  property,
  PropertyValues,
  query,
  state,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { html, nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';

import { getStringArrayConverter } from '../../utils/component.utils';
import { generateUid } from '../../utils/portal.utils';
import { getHostElementFromChild, getParentElementBySelector } from '../../utils/mixin.utils';

import type { OverlayDirective } from '../overlay/overlay.directive';
import type { TooltipAnchoring, TooltipTrigger } from './tooltip.directive.types';
import { generatePseudoClientRect, mapTriggerEvents } from './tooltip.directive.utils';

import { hostStyles } from '../../host.styles';
import style from './tooltip.directive.scss';

export const TOOLTIP_DIRECTIVE_STYLES = css`
  ${unsafeCSS(style)}
`;

export const TOOLTIP_PLACEMENTS: Placement[] = [
  'bottom-start',
  'bottom',
  'bottom-end',
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'left-start',
  'left',
  'left-end',
];
export const TOOLTIP_PORTAL = 'tooltip';
export const TOOLTIP_SHOW_DELAY = 300;
export const TOOLTIP_HIDE_DELAY = 200;
export const TOOLTIP_DEFAULT_EVENTS: ReadonlyArray<keyof HTMLElementEventMap> = ['keydown'];
export const TOOLTIP_SHOW_EVENTS: ReadonlyArray<keyof HTMLElementEventMap> = [
  'focus',
  'mouseenter',
  'mousemove',
  'click',
];
export const TOOLTIP_HIDE_EVENTS: ReadonlyArray<keyof HTMLElementEventMap> = ['blur', 'mouseleave', 'keydown'];

/**
 * This directive allows adding tooltip behavior to any element it is placed in.
 * It basically allows to use any element as it's carrying the logic to place the
 * slotted element, without being limited to a specific one. Nevertheless we
 * highly recommended to use the `zui-tooltip` ui component as this is covered
 * by our tests only.
 * For more informations on the tooltip itself, just visit its documentation.
 *
 * > Use arbitrary elements on your own risk. Stick to the provided
 * > `zui-tooltip` component instead.
 *
 * > Don't forget to provide an `aria-describedby` attribute pointing to the
 * > tooltip to improve accessibility.
 *
 * @example
 * HTML:
 * ```html
 * <zui-button aria-describedby="tooltip">
 *   <span>Me, the label!</span>
 *   <zui-tooltip-directive delayed id="tooltip" trigger="focus hover">
 *     <zui-tooltip emphasis="warning">I warned you!</zui-tooltip>
 *   </zui-tooltip>
 * </zui-button>
 * ```
 *
 * @slot - The default slot content will be used as tooltip.
 */
@customElement('zui-tooltip-directive')
export class TooltipDirective extends LitElement {
  static readonly styles = [hostStyles, TOOLTIP_DIRECTIVE_STYLES];

  /**
   * enforces the "role" attribute for a11y reasons
   */
  @property({ reflect: true, type: String })
  role = 'tooltip';

  /**
   * allows delaying the transitions
   * (prevents tooltip from appearing until the cursor stops moving)
   */
  @property({ reflect: true, type: Boolean })
  delayed = false;

  /**
   * whether to align the tooltip to the cursor or to the assigned component
   */
  @property({ reflect: true, type: String })
  anchoring: TooltipAnchoring = 'cursor';

  /**
   * defines the triggers responsible for toggling the tooltip;
   * as the property is an array, the attribute is a **space separated** list
   */
  @property({ reflect: true, converter: getStringArrayConverter<TooltipTrigger>() })
  trigger: TooltipTrigger[] = ['click', 'focus'];

  /**
   * Allows setting a custom trigger host selector.
   * Defines the closest element upwards the tree matching the selector.
   */
  @property({ reflect: true, attribute: 'trigger-host-selector', type: String })
  triggerHostSelector = '*';

  /**
   * internal flag for tooltip visibility to be targeted by css
   *
   * @private
   */
  @property({ reflect: true, type: Boolean, attribute: 'zui-internal-visible' })
  visible = false;

  /**
   * The destination overlay name is passed through.
   */
  @property({ reflect: true, type: String })
  portal = `${TOOLTIP_PORTAL}-${generateUid()}`;

  /**
   * An optional level to be used if the portal is created dynamically.
   */
  @property({ reflect: true, type: Number })
  level?: number;

  @query('zui-overlay-directive')
  private readonly _overlayRef: OverlayDirective | null;

  @state()
  // will be used to store the current mouse cursor position
  private _clientRect: ClientRectObject = generatePseudoClientRect(0, 0);

  // stores all derived event names which are used as tooltip triggers
  private readonly _triggerEvents = new Set<keyof HTMLElementEventMap>(TOOLTIP_DEFAULT_EVENTS);

  // stores all currently registered trigger event listeners
  private readonly _triggerListeners = new Map<keyof HTMLElementEventMap, EventListener>();

  // using the mouse cursor as positioning target requires us to build a so called `VirtualElement`
  // which implements at least the method to retrieve the `ClientRect` which itself contains in
  // particular the current cursor position
  private readonly _pseudoCursor: VirtualElement = { getBoundingClientRect: () => this._clientRect as ClientRect };

  // handling delays with css transitions doesn't work out as expected, thus we have to store
  // the created timeouts on the listeners which shouldn't interfere with each other
  private _visibilityTimeout: number | undefined;

  // the click event in conjunction with focus triggers both events at the same time, which forces
  // the always second handled click event to close the tooltip again right after it has been shown
  // through the first arriving focus event - thus we have to remember this until the click
  private _hasBeenFocusedPreviously = false;

  /**
   * Reference to the parent element. This is used to be able to remove formerly added listeners
   */
  private _parentElementRef: Element;

  connectedCallback(): void {
    super.connectedCallback();
    // it is safe to assume, that parentElement is always set, because it would only be null for detached
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._parentElementRef = getParentElementBySelector(this.parentElement!, this.triggerHostSelector) as HTMLElement;
    this._addTriggerListeners();
  }

  disconnectedCallback(): void {
    this._removeTriggerListeners();
    this._removeMoveListener();
    super.disconnectedCallback();
  }

  // as the triggers are conveniently named they have to
  // be mapped to the "real" DOM event names
  private _deriveTriggerEvents(): void {
    // first clear existing events
    this._triggerEvents.clear();
    // add mapped events then
    mapTriggerEvents(this.trigger, [...TOOLTIP_DEFAULT_EVENTS]).forEach((event) => this._triggerEvents.add(event));
  }

  // adds necessary listeners to parent element
  private _addTriggerListeners(): void {
    // resolve required event names
    this._triggerEvents.forEach((trigger) => {
      const listener: EventListener = () => this._handleParentEvent(trigger);
      this._parentElementRef.addEventListener(trigger, listener, false);
      this._triggerListeners.set(trigger, listener);
    });
  }

  // removes all listeners from parent element
  private _removeTriggerListeners(): void {
    this._triggerListeners.forEach((listener, trigger) => {
      this._parentElementRef.removeEventListener(trigger, listener, false);
    });
    this._triggerListeners.clear();
  }

  // handle the triggered event properly
  // we pass the name of the trigger as well, because we can't rely on the `event.type` as it's
  // simply typed as `string` in lib.dom
  // eslint-disable-next-line max-statements
  private _handleParentEvent<K extends keyof HTMLElementEventMap>(name: K): void {
    // remember focus events
    if (name === 'focus') {
      this._hasBeenFocusedPreviously = true;
    }
    // if we receive a click right after focus, we won't handle it, because it would cause the
    // toggle mechanism to hide the tooltip right after it has been shown by the focus
    if (name === 'click' && this._hasBeenFocusedPreviously) {
      this._hasBeenFocusedPreviously = false;
      return;
    }

    // TODO: properly initialize visible to make TS happy
    // determine the next visibility state
    let visible: boolean;
    if (TOOLTIP_SHOW_EVENTS.includes(name)) {
      visible = true;
    }
    if (TOOLTIP_HIDE_EVENTS.includes(name)) {
      visible = false;
    }

    // either delayed or not, this is always to do
    const finalize = (visible: boolean): void => {
      if (this.visible !== visible) {
        this.visible = visible;
        // once visible, the overlay may need a little advise to re-position after rendering
        requestAnimationFrame(() => this._overlayRef?.forcePositioning());
      }
    };

    // apply directly or use a timeout if delayed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because we can compare undef with false
    if (this.delayed || visible === false) {
      window.clearTimeout(this._visibilityTimeout);
      this._visibilityTimeout = window.setTimeout(
        () => finalize(visible),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        visible ? TOOLTIP_SHOW_DELAY : TOOLTIP_HIDE_DELAY
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      finalize(visible);
    }
  }

  // add listener for cursor update
  private _addMoveListener(): void {
    // update the pseudo cursor on mouse move
    this._parentElementRef.addEventListener('mousemove', this._updatePseudoCursor.bind(this), false);
    // update the initial position but take cursor offset into account
    const { bottom, left } = this._parentElementRef.getBoundingClientRect();
    this._clientRect = generatePseudoClientRect(left, bottom - 12);
  }

  // remove the cursor updater
  private _removeMoveListener(): void {
    this._parentElementRef.removeEventListener('mousemove', this._updatePseudoCursor.bind(this), false);
  }

  private _updatePseudoCursor({ clientX, clientY }: MouseEvent): void {
    this._clientRect = generatePseudoClientRect(clientX, clientY);
    this._overlayRef?.forcePositioning();
  }

  // delivers either the simulated element containing the mouse position,
  // or the parents host reference
  protected getPositionReference(): VirtualElement {
    // are we directly in shadow DOM?
    return this.anchoring === 'cursor' ? this._pseudoCursor : getHostElementFromChild(this, false);
  }

  protected offsetHandler({ placement }: { placement: Placement }): [number, number] {
    if (this.anchoring === 'cursor') {
      // we need an offset of 4px to the cursor, but since we can't know about
      // the cursor size we have to assume that the default cursor with
      // approximately 16px height is used
      const addCursor = placement.includes('bottom') || placement.includes('right');
      return [0, addCursor ? 22 : 6] as [number, number];
    } else {
      // fixed positioning should always have an offset of 8px
      return [0, 8];
    }
  }

  protected updated(changedProperties: PropertyValues<this & { _visible }>): void {
    // re-apply all listeners if the trigger changes
    if (changedProperties.has('trigger')) {
      this._deriveTriggerEvents();
      this._removeTriggerListeners();
      this._addTriggerListeners();
    }

    // add listeners for mouse cursor
    if (changedProperties.has('anchoring')) {
      this._removeMoveListener();
      if (this.anchoring) {
        this._addMoveListener();
      }
    }

    super.updated(changedProperties);
  }

  protected render(): TemplateResult {
    return html`${this.visible
      ? html`
          <zui-overlay-directive
            clone
            flip
            flip-padding="16"
            level="${ifDefined(this.level)}"
            portal="${ifDefined(this.portal)}"
            .offsetHandler="${this.offsetHandler.bind(this)}"
            .placements="${TOOLTIP_PLACEMENTS}"
            .positionReferenceCallback="${this.getPositionReference.bind(this)}"
          >
            <slot></slot>
          </zui-overlay-directive>
        `
      : nothing}`;
  }
}
