import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  PropertyValues,
  query,
  state,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { hostStyles } from '../../../host.styles';
import { DateTime } from 'luxon';
import type { VariationPlacement } from '@popperjs/core/lib/enums';
import type { Instance as PopperInstance } from '@popperjs/core/lib/types';
import { defaultModifiers, popperGenerator } from '@popperjs/core/dist/esm/popper-lite';
import offset from '@popperjs/core/dist/esm/modifiers/offset';
import { event } from '../../../decorators/event.decorator';
import { RealBaseElement } from '../../base/BaseElement';
import { EventWithTarget } from '../../../types';
import styles from './textfield-date-picker.component.scss';
import {
  DatePickerWeekdayEnum,
  daysOfWeekConverter,
  getDateTimesFromJsDates,
  getDefaultLocale,
  hasWeekday,
  isoDateConverter,
  someIsSameDay,
  someIsSameMonth,
  someIsSameYear,
} from '../utils/date-picker.utils';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { FormEnabledElement, FormValidationElement } from '../../../mixins/form-participation/form-participation.types';
import { isDefined } from '../../../utils/component.utils';

import { DatePickerInput } from '../date-picker-input/date-picker-input.component';
import { Popover } from '../../popover/popover.component';
import { Tooltip } from '../../tooltip/tooltip.component';

enum CurrentPickerEnum {
  Day = 'day',
  Month = 'month',
  Year = 'year',
}

const textfieldDatePickerStyles = css`
  ${unsafeCSS(styles)}
`;

const createPopper = popperGenerator({
  defaultModifiers: [
    ...defaultModifiers,
    {
      ...offset,
      options: {
        offset: [0, 8],
      },
    },
  ],
});

/**
 * The textfield date picker component shows an input and opens the date picker when the interactive icon is selected.
 *
 * ## Figma
 * - [Desktop - Component Library - Text Field](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---2.7?node-id=384:64193)
 * - [Desktop - Component Library - Date Picker](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=21190%3A191753)
 * - [Styleguide – Desktop - Text Field](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=48826:397355)
 * - [Styleguide – Desktop - Date Picker](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=6557%3A241082)
 *
 * @example
 * HTML:
 *
 * Textfield date picker
 * ```html
 * <div class="side-bar">
 *   <zui-textfield-date-picker
 *     close-on-date-selected
 *     disabled-days-of-week="Mo,Tuesday"
 *     locale="en-US"
 *     max="2010-01-01T00:00:00.000+01:00"
 *     min="2020-12-31T23:59:59.999+01:00"
 *     name="textfieldDatePicker"
 *     parent-selector=".side-bar"
 *     placeholderDay="DD"
 *     placeholderMonth="MM"
 *     placeholderYear="YYYY"
 *     value="2021-07-21T11:00:00.000+02:00"
 *   >
 *   </zui-textfield-date-picker>
 * </div>
 * ```
 *
 * Form example
 * ```html
 * <form>
 *   <button type="reset">reset</button>
 *   <button type="submit">submit</button>
 *   <zui-textfield-date-picker
 *    ...
 *   >
 *   </zui-textfield-date-picker>
 * </form>
 * ```
 *
 * @fires {CustomEvent} textfield-date-picker-date-selected - emits the selected day
 * @fires {CustomEvent} textfieldDatePickerDateSelected - (Deprecated) emits the selected day
 * @fires change - The event that fires when user has changed <code>value</code>
 * @fires input - The event that fires when there has been any user input
 *
 * @cssprop --zui-textfield-date-picker-input-width - size of the input - default width is 120px
 * @cssprop --zui-textfield-date-picker-day-placeholder-width - override default day input placeholder width that is optimized for DD
 * @cssprop --zui-textfield-date-picker-month-placeholder-width - override default month input placeholder width that is optimized for MM
 * @cssprop --zui-textfield-date-picker-year-placeholder-width - override default year input placeholder width that is optimized for YYYY
 */
@customElement('zui-textfield-date-picker')
export class TextfieldDatePicker
  extends FormValidationMixin(FormDataHandlingMixin(RealBaseElement))
  implements FormValidationElement<FormEnabledElement> {
  static get styles(): CSSResultArray {
    return [hostStyles, textfieldDatePickerStyles];
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  static readonly DISABLED_ERROR_MESSAGE = 'This date is not allowed.';
  static readonly INVALID_ERROR_MESSAGE = 'Please enter a valid date.';
  static readonly REQUIRED_ERROR_MESSAGE = 'A date is required.';
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * whether the picker should be closed after date selection or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'close-on-date-selected' })
  closeOnDateSelected = false;

  /**
   * disabled dates
   */
  @property({ type: Array, attribute: false })
  disabledDates: Date[] = [];

  private get _disabledDatesDT(): DateTime[] {
    return getDateTimesFromJsDates(this.disabledDates);
  }

  /**
   * disabled months
   */
  @property({ type: Array, attribute: false })
  disabledMonths: Date[] = [];

  private get _disabledMonthsDT(): DateTime[] {
    return getDateTimesFromJsDates(this.disabledMonths);
  }

  /**
   * disabled years
   */
  @property({ type: Array, attribute: false })
  disabledYears: Date[] = [];

  private get _disabledYearsDT(): DateTime[] {
    return getDateTimesFromJsDates(this.disabledYears);
  }

  /**
   * disabled days of week
   */
  @property({ reflect: true, type: String, attribute: 'disabled-days-of-week', converter: daysOfWeekConverter })
  disabledDaysOfWeek: DatePickerWeekdayEnum[] = [];

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * max date
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  max: Date | null = null;

  private get _maxDateDT(): DateTime | undefined {
    return this.max ? DateTime.fromJSDate(this.max) : undefined;
  }

  // TODO: remove in version 2.0
  /**
   * (Deprecated) maxDate use max instead
   *
   * @returns Date max date
   *
   * @deprecated use max instead
   */
  @property({ reflect: true, type: String, attribute: 'max-date', converter: isoDateConverter })
  get maxDate(): Date | null {
    return this.max;
  }

  set maxDate(value: Date | null) {
    console.warn('Deprecated property maxDate used. Use max instead.');

    this.max = value;
  }

  /**
   * min date
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  min: Date | null = null;

  private get _minDateDT(): DateTime | undefined {
    return this.min ? DateTime.fromJSDate(this.min) : undefined;
  }

  // TODO: remove in version 2.0
  /**
   * (Deprecated) minDate use min instead
   *
   * @returns Date min date
   *
   * @deprecated use min instead
   */
  @property({ reflect: true, type: String, attribute: 'min-date', converter: isoDateConverter })
  get minDate(): Date | null {
    return this.min;
  }

  set minDate(value: Date | null) {
    console.warn('Deprecated property minDate used. Use min instead.');

    this.min = value;
  }

  /**
   * placeholder day
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-day' })
  placeholderDay = 'DD';

  /**
   * placeholder month
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-month' })
  placeholderMonth = 'MM';

  /**
   * placeholder year
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-year' })
  placeholderYear = 'YYYY';

  /**
   * The default alignment of the date picker popover is flush with the right side of the textfield.
   * By default the calculation is based on the viewport. When there isn't enough space it is left aligned.
   * When used inside a container we do not want to calculate the space based on the viewport but inside the container.
   * To limit the calculation on the container you can define this by class or id.
   */
  @property({ reflect: true, type: String, attribute: 'parent-selector' })
  parentSelector: string | null = null;

  /**
   * required
   */
  @property({ reflect: true, type: Boolean })
  required = false;

  /**
   * show the calendar UI or not, defaults to false
   */
  @property({ reflect: true, type: Boolean, attribute: 'show-calendar' })
  showCalendar = false;

  /**
   * selected date value
   *
   * @returns value Date | null
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  get value(): Date | null {
    return this._value;
  }

  set value(val: Date | null) {
    const oldVal = this._value;
    this._value = val;
    this._currentDate = this._value ?? new Date();
    this.requestUpdate('value', oldVal);
  }

  private get _valueDT(): DateTime | undefined {
    return this.value ? DateTime.fromJSDate(this.value) : undefined;
  }

  /**
   * (Deprecated) alternative disabled date selected warning message
   *
   * @deprecated use setValidityMessages({ customError: '...' }) for disabled dates instead
   * @deprecated use setValidityMessages({ rangeOverflow: '...' }) for exceeding max dates instead
   * @deprecated use setValidityMessages({ rangeUnderflow: '...' }) for undershot min dates instead
   */
  @property({ reflect: true, type: String, attribute: 'warning-disabled' })
  warningDisabled: string;

  /**
   * (Deprecated) alternative invalid date selected warning message
   *
   * @deprecated use setValidityMessages({ badInput: '...' }) instead
   */
  @property({ reflect: true, type: String, attribute: 'warning-invalid' })
  warningInvalid: string;

  /**
   * optional weekstart that overrides the locale
   */
  @property({ reflect: true, type: String, attribute: 'week-start' })
  weekStart: DatePickerWeekdayEnum;

  /**
   * Emits a custom textfield-date-picker-date-selected event when a date is selected
   *
   * @param detail object with value
   * @param detail.value the selected date
   *
   * @private
   */
  @event({
    eventName: 'textfield-date-picker-date-selected',
    bubbles: true,
    composed: true,
  })
  emitTextfieldDatePickerDateSelectedEvent(detail: { value: Date | null }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('textfieldDatePickerDateSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('textfield-date-picker-date-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * @private
   */
  @event({ eventName: 'input', bubbles: true, cancelable: false, composed: true })
  emitInputEvent(): void {
    this.dispatchEvent(
      new Event('input', {
        bubbles: true,
        composed: true,
      })
    );
  }

  /**
   * emits a change Event
   *
   * @private
   */
  @event({
    eventName: 'change',
    bubbles: true,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        composed: false,
      })
    );
  }

  private get _currentDateDT(): DateTime {
    return DateTime.fromJSDate(this._currentDate);
  }

  @state()
  private _currentPicker = CurrentPickerEnum.Day;

  @state()
  private _datePickerPlacement: Extract<VariationPlacement, 'bottom-start' | 'bottom-end'> = 'bottom-start';

  @state()
  private _internalDateTimeValue: Record<'day' | 'month' | 'year', number | null> = {
    day: null,
    month: null,
    year: null,
  };

  @query('zui-date-picker-input')
  private _datePickerInput: DatePickerInput;

  @query('zui-popover')
  private _popover: Popover;

  @query('zui-tooltip')
  private _tooltip: Tooltip;

  private _value: Date | null = null;
  private _currentDate: Date;

  private _popperDatePicker: PopperInstance;
  private _popperTooltip: PopperInstance;
  private _textfieldDatePickerResizeObserver = new ResizeObserver(async () => {
    requestAnimationFrame(() => {
      const { left: viewPortLeft } = this.getBoundingClientRect();
      const { left: parentLeft } =
        this.parentSelector && this.closest(this.parentSelector)
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.closest(this.parentSelector)!.getBoundingClientRect()
          : { left: 0 };
      const { width: datePickerInputWidth } = this._datePickerInput.getBoundingClientRect();

      this._datePickerPlacement =
        datePickerInputWidth >= 290 || viewPortLeft - parentLeft + datePickerInputWidth > 290
          ? 'bottom-end'
          : 'bottom-start';

      this._popperDatePicker?.setOptions({
        placement: this._datePickerPlacement,
      });
    });
  });

  constructor() {
    super();

    this.addValidator({
      type: 'badInput',
      validator: this._invalidDateValidator,
    });

    this.addValidator({
      type: 'customError',
      validator: this._disabledDateValidator,
      validatesOnProperties: ['disabledDates', 'disabledMonths', 'disabledYears', 'disabledDaysOfWeek'],
    });

    this.addValidator({
      type: 'rangeOverflow',
      validator: this._rangeOverflowValidator,
      validatesOnProperties: ['max'],
    });

    this.addValidator({
      type: 'rangeUnderflow',
      validator: this._rangeUnderflowValidator,
      validatesOnProperties: ['min'],
    });

    this.addValidator({
      type: 'valueMissing',
      validator: this._requiredDateValidator,
      validatesOnProperties: ['required'],
    });

    // TODO: remove deprecated warning messages in version 2.0
    this.setDefaultValidityMessages({
      badInput: this.warningInvalid ?? TextfieldDatePicker.INVALID_ERROR_MESSAGE,
      customError: this.warningDisabled ?? TextfieldDatePicker.DISABLED_ERROR_MESSAGE,
      rangeOverflow: this.warningDisabled ?? TextfieldDatePicker.DISABLED_ERROR_MESSAGE,
      rangeUnderflow: this.warningDisabled ?? TextfieldDatePicker.DISABLED_ERROR_MESSAGE,
      valueMissing: TextfieldDatePicker.REQUIRED_ERROR_MESSAGE,
    });
  }

  private get _disabledDateConditions(): ((date: DateTime) => boolean)[] {
    return [
      (date): boolean => someIsSameDay(date, this._disabledDatesDT),
      (date): boolean => someIsSameMonth(date, this._disabledMonthsDT),
      (date): boolean => someIsSameYear(date, this._disabledYearsDT),
      (date): boolean => hasWeekday(date, this.disabledDaysOfWeek),
    ];
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener('textfield-date-picker-date-selected', this._handleDatePickerDateSelectedEvent);

    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.addEventListener('click', this._handleOutsideClick);
  }

  disconnectedCallback(): void {
    this._popperDatePicker.destroy();
    this._popperTooltip.destroy();
    [this, this._datePickerInput].forEach((element) => this._textfieldDatePickerResizeObserver.unobserve(element));

    this.removeEventListener('textfield-date-picker-date-selected', this._handleDatePickerDateSelectedEvent);

    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.removeEventListener('click', this._handleOutsideClick);

    super.disconnectedCallback();
  }

  /**
   * @private
   */
  formResetCallback(): void {
    super.formResetCallback();

    this._internalDateTimeValue = {
      day: null,
      month: null,
      year: null,
    };
  }

  private _disabledDateValidator = (): boolean =>
    // TODO: this looks like a potential bug
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._valueDT ? !this._disabledDateConditions.some((predicate) => predicate(this._valueDT!)) : true;
  private _invalidDateValidator = (): boolean => (this._valueDT ? this._valueDT.isValid : true);
  private _rangeOverflowValidator = (): boolean =>
    this._valueDT && this._maxDateDT ? !(this._valueDT.toMillis() > this._maxDateDT.toMillis()) : true;
  private _rangeUnderflowValidator = (): boolean =>
    this._valueDT && this._minDateDT ? !(this._valueDT.toMillis() < this._minDateDT.toMillis()) : true;
  private _requiredDateValidator = (): boolean => this.required === false || isDefined(this.value);

  // todo: this should be removed when a reusable solution has been implemented
  // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
  private _handleOutsideClick = (event: EventWithTarget<TextfieldDatePicker | Element>): void => {
    if (!this.showCalendar) {
      return;
    }

    const isInsideClick =
      this.isSameNode(event.target) &&
      event.composedPath().some((path) => path instanceof DatePickerInput || path instanceof Popover);

    if (!isInsideClick) {
      this.showCalendar = false;
    }
  };

  private get _inputPartDayValue(): number | null {
    return this._valueDT?.day ?? this._internalDateTimeValue.day;
  }

  private get _inputPartMonthValue(): number | null {
    return this._valueDT?.month ?? this._internalDateTimeValue.month;
  }

  private get _inputPartYearValue(): number | null {
    return this._valueDT?.year ?? this._internalDateTimeValue.year;
  }

  private get _showValidationWarning(): boolean {
    return this.willValidate && this.invalid;
  }

  private async _handleDatePickerInputCalendarSelected(): Promise<void> {
    this.showCalendar = !this.showCalendar;

    if (this.showCalendar) {
      await this._popperDatePicker.setOptions({
        placement: this._datePickerPlacement,
      });
    }
  }

  private _handleDatePickerInputChanged({
    detail,
  }: CustomEvent<{ value: Date | null; error?: Record<'day' | 'month' | 'year', number | null> }>): void {
    const oldValue = this.value;

    this.value = detail.value;

    if (detail.error !== undefined) {
      this._internalDateTimeValue = { ...detail.error };
    }

    // do not emit an event when only parts changes and the previous value is not defined
    if (this._value === null && oldValue === null) {
      return;
    }

    this.emitTextfieldDatePickerDateSelectedEvent({
      value: this.value,
    });
  }

  private _handleDatePickerCurrentDateChanged({
    detail,
  }: CustomEvent<{ currentDate: Date; currentPicker: CurrentPickerEnum }>): void {
    // TODO: this looks, like the event could also emit a nullish value...
    this._currentDate = detail.currentDate;
    this._currentPicker = detail.currentPicker;
  }

  private _handleDatePickerInputFocused(): void {
    this.showCalendar = false;
  }

  private _handleDatePickerDateSelected({ detail }: CustomEvent<{ value: Date }>): void {
    this.value = detail.value;
    this.showCalendar = !this.closeOnDateSelected;

    this.emitTextfieldDatePickerDateSelectedEvent({ value: detail.value });
    this.emitInputEvent();
  }

  private _handleDatePickerDateSelectedEvent(): void {
    this.emitChangeEvent();
  }

  protected async firstUpdated(changedProperties: PropertyValues): Promise<void> {
    super.firstUpdated(changedProperties);

    // TODO: this does nothing, because in firstUpdated this will always immediately resolv
    await this.updateComplete;

    this._popperDatePicker = createPopper(this._datePickerInput, this._popover, {
      placement: this._datePickerPlacement,
    });
    this._popperTooltip = createPopper(this._datePickerInput, this._tooltip, { placement: 'bottom-start' });

    [this, this._datePickerInput].forEach((element) => this._textfieldDatePickerResizeObserver.observe(element));
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('showCalendar')) {
      if (this.showCalendar === false) {
        this._currentPicker = CurrentPickerEnum.Day;
      }
    }

    // TODO: remove console warning for deprecations in version 2.0
    if (changedProperties.has('warningDisabled') || changedProperties.has('warningInvalid')) {
      console.warn('Deprecated warning messages were used. Use setValidityMessages() instead.');
    }
  }

  protected render(): TemplateResult {
    return html`
      <zui-date-picker-input
        ?calendar-opened="${this.showCalendar}"
        ?disabled="${this.disabled}"
        ?invalid="${this._showValidationWarning}"
        ?readonly="${this.readonly}"
        .inputPartDayValue="${this._inputPartDayValue}"
        .inputPartMonthValue="${this._inputPartMonthValue}"
        .inputPartYearValue="${this._inputPartYearValue}"
        locale="${this.locale}"
        placeholder-day="${this.placeholderDay}"
        placeholder-month="${this.placeholderMonth}"
        placeholder-year="${this.placeholderYear}"
        selected-date="${ifDefined(this._valueDT)}"
        @date-picker-input-calendar-selected="${this._handleDatePickerInputCalendarSelected}"
        @date-picker-input-changed="${this._handleDatePickerInputChanged}"
        @date-picker-input-focused="${this._handleDatePickerInputFocused}"
      >
      </zui-date-picker-input>
      <zui-popover class="popover">
        <zui-date-picker
          .disabledDates="${this.disabledDates}"
          .disabledMonths="${this.disabledMonths}"
          .disabledYears="${this.disabledYears}"
          ?close-on-date-selected="${this.closeOnDateSelected}"
          current-date="${ifDefined(this._currentDateDT)}"
          current-picker="${this._currentPicker}"
          disabled-days-of-week="${this.disabledDaysOfWeek.join(',')}"
          locale="${this.locale}"
          max="${this._maxDateDT}"
          min="${this._minDateDT}"
          selected-date="${ifDefined(this._valueDT)}"
          week-start="${ifDefined(this.weekStart ?? undefined)}"
          @date-picker-date-selected="${this._handleDatePickerDateSelected}"
          @date-picker-current-date-changed="${this._handleDatePickerCurrentDateChanged}"
        >
        </zui-date-picker>
      </zui-popover>
      <zui-tooltip class="tooltip" emphasis="warning">${this.validationMessage}</zui-tooltip>
    `;
  }
}
