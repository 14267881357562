import { CSSResult, css } from 'lit-element';

/**
 * @type {CSSResult}
 * @exports
 */
export const hostStyles: CSSResult = css`
  :host {
    display: block;
  }
  :host([hidden]) {
    display: none;
  }

  ::selection {
    background-color: var(--zui-color-selection-background);
  }
`;
