import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  TemplateResult,
  unsafeCSS,
  queryAssignedNodes,
  PropertyValues,
} from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import { event } from '../../../decorators/event.decorator';
import { getStringArrayConverter } from '../../../utils/component.utils';

import style from './content-accordion.component.scss';

import { ContentAccordionDetails } from '../content-accordion-details/content-accordion-details.component';

type Size = 's' | 'm' | 'l';

const contentAccordionStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * This component allow "stacking" multiple accordion details.
 * It can work in single and multi mode. Opening / closing is done by clicking the mouse on any item but also by setting
 * the value attribute for content accordion. In the case of the multi select option, items in the value attribute
 * are separated with a comma (see example below).
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=15382%3A3275)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=33682%3A167)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-content-accordion integrated multi value="one,two">
 *  <zui-content-accordion-details value="one" headline-text="Enabled"></zui-content-accordion-details>
 *  <zui-content-accordion-details value="two" headline-text="Enabled">></zui-content-accordion-details>
 *  <zui-content-accordion-details value="three" headline-text="Enabled">></zui-content-accordion-details>
 * </zui-content-accordion>
 * ```
 *
 *
 * @slot default - Here you can insert your zui-accordion-details items
 * @fires {CustomEvent} content-accordion-open-state-changed - emits an array with opened items, when content
 * accordion details is clicked.
 */
@customElement('zui-content-accordion')
export class ContentAccordion extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, contentAccordionStyles];
  }

  /**
   * Defines possible sizes (s/m/l). Default: m.
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  /**
   * Defines integrated attribute
   */
  @property({ reflect: true, type: Boolean })
  integrated = false;

  /**
   * It is possible to set the attribute: disabled.
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * It is possible to set multi select, which allows you to open several items at the same time
   */
  @property({ reflect: true, type: Boolean })
  multi = false;

  /**
   * Specifies which item is open or which items are open when multi is selected.
   * Values are comma-separated on the attribute and an array on the property.
   */
  @property({ reflect: true, type: String, converter: getStringArrayConverter(',') })
  value: string[] = [];

  /**
   * Emit close event
   *
   * @private
   */
  @event({
    eventName: 'content-accordion-open-state-changed',
    bubbles: false,
    composed: false,
    cancelable: false,
  })
  emitContentAccordionOpenStateChanged(): void {
    this.dispatchEvent(
      new CustomEvent('content-accordion-open-state-changed', {
        bubbles: false,
        composed: false,
        cancelable: false,
        detail: {
          value: this.value,
        },
      })
    );
  }

  @queryAssignedNodes('', true, 'zui-content-accordion-details')
  private _assignedAccordion: ContentAccordionDetails[];

  private _propagateDisabled = false;

  private _propagateState(): void {
    this._assignedAccordion.forEach((accordion) => {
      if (this._propagateDisabled) {
        accordion.disabled = this.disabled;
      }
      accordion.integrated = this.integrated;
      accordion.size = this.size;

      if (this.value.includes(accordion.value)) {
        accordion.setAttribute('open', '');
      } else {
        accordion.removeAttribute('open');
      }
    });
  }

  private _handleOpenStateChanged(event: CustomEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();

    if (!event.detail.opened) {
      this.value = this.value.filter((element) => element !== event.detail.value);
    } else if (this.multi) {
      this.value = [...this.value, event.detail.value];
    } else if (!this.multi) {
      this.value = [event.detail.value];
    }

    this.emitContentAccordionOpenStateChanged();
  }

  private _handleSlotChange(): void {
    this._propagateState();
  }

  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);
    if (this.disabled || changedProperties.get('disabled')) {
      this._propagateDisabled = true;
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    this._propagateState();
  }

  protected render(): TemplateResult | void {
    return html` <slot
      @content-accordion-details-open-state-changed="${this._handleOpenStateChanged}"
      @slotchange="${this._handleSlotChange}"
    ></slot>`;
  }
}
