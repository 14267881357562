import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  state,
  TemplateResult,
  unsafeCSS,
  query,
} from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { classMap } from 'lit-html/directives/class-map';
import { hostStyles } from '../../../host.styles';
import { event } from '../../../decorators/event.decorator';
import style from './content-accordion-details.component.scss';
import { PropertyValues } from 'lit-element/lib/updating-element';

type Size = 's' | 'm' | 'l';
const WIDTH_BREAKPOINT = 560;

const contentAccordionDetailsStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * This component is for use in content accordion.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=15382%3A3275)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=33682%3A167)
 *
 * @example
 * HTML:
 *
 * Standalone:
 * ```html
 *   <zui-content-accordion-details value="one" headline-text="Simple text"></zui-content-accordion-details>
 * ```
 *
 * Integrated:
 * ```html
 *   <zui-content-accordion-details value="one" integrated headline-text="Simple text"></zui-content-accordion-details>
 * ```
 *
 * @slot default - Here you can insert your own html content
 * @fires {CustomEvent<{ opened: boolean, value: string }>} content-accordion-details-open-state-changed - emits when content accordion details is clicked
 */
@customElement('zui-content-accordion-details')
export class ContentAccordionDetails extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, contentAccordionDetailsStyles];
  }

  /**
   * Defines possible sizes (s/m/l)
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  /**
   * Defines integrated attribute.
   */
  @property({ reflect: true, type: Boolean })
  integrated = false;

  /**
   * Defines open
   */
  @property({ reflect: true, type: Boolean })
  open = false;

  /**
   * It is possible to set the attribute: disabled.
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Defines title of header
   */
  @property({ reflect: true, type: String, attribute: 'headline-text' })
  headlineText: string;

  /**
   * Defines value
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Emit close event
   *
   * @param detail { opened: boolean, value: string | null }
   * @param detail.opened whether the content accordion is opened or closed
   * @param detail.value value of the list accordion or null when undefined
   *
   * @private
   */
  @event({
    eventName: 'content-accordion-details-open-state-changed',
    bubbles: true,
    composed: true,
    cancelable: false,
  })
  emitContentAccordionDetailsOpenStateChanged(detail: { opened: boolean; value: string | null }): void {
    this.dispatchEvent(
      new CustomEvent('content-accordion-details-open-state-changed', {
        bubbles: true,
        composed: true,
        cancelable: false,
        detail,
      })
    );
  }

  // Computing the height of the content after clicking or changing property to improve the animation,
  // which cannot be animated in the case of height: auto.
  private get _contentSlotHeight(): number {
    return this._contentSlotWrapper.offsetHeight;
  }

  @query('.content-slot')
  private _contentSlotWrapper: HTMLDivElement;

  @state()
  private _hasWideWidth = false;

  @state()
  private _contentHeight = 0;

  // set a observer of container width to set a 'wide-container' class when width equal or greater than 560px for extra paddings.
  private _resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    if (entries[0].contentRect.width) {
      const width = entries[0].contentRect.width;
      this._hasWideWidth = width >= WIDTH_BREAKPOINT;
    }
  });

  connectedCallback(): void {
    super.connectedCallback();
    this._resizeObserver.observe(this);
  }

  disconnectedCallback(): void {
    this._resizeObserver.disconnect();
    super.disconnectedCallback();
  }

  private _handleClick(mouseEvent: Event): void {
    if (this.disabled) {
      mouseEvent.stopPropagation();
      mouseEvent.stopImmediatePropagation();
      return;
    }
    if (!this.open) {
      this._contentHeight = this._contentSlotHeight;
    }

    this.open = !this.open;
    this.emitContentAccordionDetailsOpenStateChanged({ opened: this.open, value: this.value ? this.value : null });
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    this._contentHeight = this._contentSlotHeight;
  }

  protected render(): TemplateResult | void {
    return html`<div class="container ${classMap({ 'wide-container': this._hasWideWidth })}">
      <div class="header" @click=${this._handleClick} tabindex="0"
        ><span>${this.headlineText}</span>
        <zui-icon-arrow-outline-arrow-outline-actually-centred-down
          size="xs"
          class="arrow"
        ></zui-icon-arrow-outline-arrow-outline-actually-centred-down
      ></div>
      <div class="content" style="--zui-content-accordion-details-content-height: ${this._contentHeight}px">
        <div class="content-slot">
          <slot></slot>
        </div>
      </div>
    </div>`;
  }
}
