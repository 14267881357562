import { css, customElement, property, TemplateResult, unsafeCSS } from 'lit-element';
import { html } from 'lit-html';
import { event } from '../../../decorators/event.decorator';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { FormEnabledElement, FormValidationElement } from '../../../mixins/form-participation/form-participation.types';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin';

import style from './slider.component.scss';
import { hostStyles } from '../../../host.styles';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { EventWithTarget } from '../../../types';
import { isValidStep, isValidValue } from '../slider.utils';

// import all the components we need
import '../slider-scale/slider-scale.component';
import '../slider-custom/slider-custom.component';
import { SliderBaseClass } from '../slider-base.class';

const sliderStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * This is the wrapper for the custom slider and the scale. The component acts similar to a standard HTML `<input
 * type="range">` with additional features and styling.
 *
 * The slider is a form element that is used to select a number value from a range of values, range is defined by a
 * min and max value.
 * It's possible to have ticks and labels to visualize the selectable values.
 *
 *  As the component uses `<zui-slider-custom>`, this component is similar in most of the behavior and styling to
 *  `<zui-slider-range>`,  with the difference that `<zui-slider>` parametrizes `<zui-slider-custom>` to have only
 *  thumb, while `<zui-slider-range>` delivers a two thumb `<zui-slider-custom>`.
 *
 * ### Tick labels
 * You can define a format pattern for ticks with the `label-format` attribute.
 * The rules for this format string can be found here: https://github.com/alexei/sprintf.js/
 *  some examples:
 *
 * - "%s" for strings
 * - "%.2f" for floats with a certain amount of decimal places
 * - "%f" for pure floats
 * - "+%.2f%%" for having a "+" as prefix and "%" as suffix
 *
 * If the label-format is not sufficient, you can define your own custom ticks by using the `<zui-slider-tick-label>` component, i.e.:
 *
 * ```html
 *  <zui-slider
 *    min="0"
 *    max="10"
 *    value="0"
 *    step="1"
 *    label-interval="1"
 *    ghost-handle="2">
 *    <zui-slider-tick-label>0.1 x</zui-slider-tick-label>
 *    <zui-slider-tick-label>0.25 x</zui-slider-tick-label>
 *    <zui-slider-tick-label>0.5 x</zui-slider-tick-label>
 *    <zui-slider-tick-label>1 x</zui-slider-tick-label>
 *    <zui-slider-tick-label>2 x</zui-slider-tick-label>
 * </zui-slider>
 * ```
 *
 * **Important note 1**: The slider only works properly if the step value is integer divisible with regard to the
 *  minimum / maximum value
 *
 * **Important note 2**: When you change the `value` via code you have to make sure that the value
 * is compatible with `min`, `max` and `step` on your own.
 * The component will not prevent you from setting invalid values via code but the visual behaviour will likely break.
 *
 * **Important note 3**: If you change the custom offset during runtime, the labels may not be aligned correctly.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2729)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102409)
 *
 * @example
 *
 * ```html
 *  <zui-slider
 *    min="0"
 *    max="10"
 *    value="0"
 *    step="1"
 *    active-line-start="3"
 *    tick-interval="0.5"
 *    label-interval="1"
 *    label-format="%.2f"
 *    readonly
 *    >
 *      <zui-slider-tick-label>one</<zui-slider-tick-label>
 *      <zui-slider-tick-label>two</<zui-slider-tick-label>
 *    </zui-slider>
 * ```
 * @cssprop --zui-slider-width - The width of the slider (and scale)
 * @cssprop --zui-slider-padding - Defines a custom offset for the slider and the scale (set it without an unit!)
 * @cssprop --zui-slider-min-padding - Defines a custom offset for the left-handed mininum area for the scale (set it without an unit!)
 * @cssprop --zui-slider-max-padding - Defines a custom offset for the right-handed maximum area for the scale (set it without an unit!)
 *
 * @fires change - The change event is fired when the value has changed
 * @fires input - The input event is fired when the value of the has received input
 * @slot - The default slot, used for the custom tick labels
 */
@customElement('zui-slider')
export class Slider
  extends FormValidationMixin(FormDataHandlingMixin(SliderBaseClass))
  implements FormValidationElement<FormEnabledElement> {
  static readonly styles = [hostStyles, sliderStyles];

  /**
   * the tabindex of the slider
   */
  @property({ reflect: true, type: Number })
  tabindex = 0;

  /**
   * the tick interval for the slider
   */
  @property({ reflect: true, type: Number, attribute: 'tick-interval' })
  tickInterval = 0;

  /**
   * the label interval for the slider
   */
  @property({ reflect: true, type: Number, attribute: 'label-interval' })
  labelInterval = 0;

  /**
   * the format template of the label. Use https://github.com/alexei/sprintf.js/ as a reference.
   */
  @property({ reflect: true, type: String, attribute: 'label-format' })
  labelFormat: string;

  /**
   * the enabled/ disabled state of the active line
   */
  @property({ reflect: true, type: Boolean, attribute: 'active-line-disabled' })
  activeLineDisabled = false;

  /**
   * If set, the ghost handle ist enabled and set to the given value
   *
   */
  @property({ reflect: true, attribute: 'ghost-handle' })
  ghostHandle: number;

  /**
   * the value of the slider
   */
  @property({
    reflect: true,
    type: Number,
  })
  value: number = this.min;

  /**
   * @private
   */
  @event({ eventName: 'change', bubbles: true, cancelable: false, composed: true })
  emitChangeEvent(): void {
    this.dispatchEvent(new Event('change', { bubbles: true, cancelable: false, composed: true }));
  }

  /**
   * @private
   */
  @event({ eventName: 'input', bubbles: true, cancelable: false, composed: true })
  emitInputEvent(): void {
    // input event is bubbling from the internal input element
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.addValidator({ validator: this._overflowValidator, type: 'rangeOverflow' });
    this.addValidator({ validator: this._underflowValidator, type: 'rangeUnderflow' });
    this.addValidator({ validator: this._stepMismatchValidator, type: 'stepMismatch' });

    this.setDefaultValidityMessages({ rangeOverflow: 'The given value is greater than max.' });
    this.setDefaultValidityMessages({ rangeUnderflow: 'The given value is less than min.' });
    this.setDefaultValidityMessages({ stepMismatch: 'The given value does not match the step size.' });
  }

  private _syncValue({ target: { value } }: EventWithTarget<Slider>): void {
    this.value = Number(value);
  }

  private _overflowValidator = (): boolean => {
    return this.value <= this.max;
  };

  private _underflowValidator = (): boolean => {
    return this.value >= this.min;
  };

  private _stepMismatchValidator = (): boolean => {
    if (this.step === 'any' || this.step === 0) {
      return true;
    }

    return isValidStep(this.min, this.max, this.step) && isValidValue(this.min, this.value, this.step);
  };

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('min') || changedProperties.has('max') || changedProperties.has('step')) {
      this.checkValidity();
    }
  }

  protected render(): TemplateResult {
    return html` <div class="main-container">
      <zui-slider-scale
        min="${this.min}"
        max="${this.max}"
        label-format="${ifDefined(this.labelFormat)}"
        label-interval="${this.labelInterval}"
        tick-interval="${this.tickInterval}"
        ?readonly="${this.readonly}"
        ?disabled="${this.disabled}"
      >
        <slot></slot>
      </zui-slider-scale>
      <zui-slider-custom
        zuiFormControl
        min="${this.min}"
        max="${this.max}"
        step="${this.step}"
        .value="${this.value}"
        ghost-handle="${ifDefined(this.ghostHandle)}"
        active-line-start="${this.activeLineStart}"
        ?active-line-disabled="${this.activeLineDisabled}"
        ?readonly="${this.readonly}"
        ?disabled="${this.disabled}"
        @input="${this._syncValue}"
      ></zui-slider-custom>
    </div>`;
  }
}
