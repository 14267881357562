import { css, CSSResult, customElement, html, property, PropertyValues, TemplateResult, unsafeCSS } from 'lit-element';
import { BaseElement } from '../base/BaseElement';
import { event } from '../../decorators/event.decorator';
import { hostStyles } from '../../host.styles';
import { registerPortal, unregisterPortal } from '../../utils/portal.utils';

import style from './portal.component.scss';

const PORTAL_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * A portal to be placed anywhere in the DOM to receive contents by `use-portal` entrances.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-portal level="1"></zui-portal>
 * ```
 *
 * @fires portal-ready - An event telling the management utilities about the portal being created and inserted into the
 *   DOM.
 *
 * @slot - The default content to be shown if nothing is projected.
 * @cssprop --zui-portal-level - sets the z-index level, detived from level attribute by default
 */
@customElement('zui-portal')
export class Portal extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, PORTAL_STYLES];
  }

  /**
   * The name of the portal (like the name of a slot);
   * if not provided it becomes the default portal.
   */
  @property({ reflect: true, type: String })
  name?: string;

  /**
   * The level property can be used to define at which
   * depth the portal will be placed.
   */
  @property({ reflect: true, type: Number })
  level?: number;

  /**
   * Allows restoring portal contents after they are destroyed.
   *
   * @deprecated Please use the clone option instead
   * @todo Remove once deprecated property is removed
   */
  @property({ reflect: true, type: Boolean })
  restore = false;

  /**
   * Clones projected contents instead of moving them.
   */
  @property({ reflect: true, type: Boolean })
  clone = false;

  /**
   * @private
   */
  @event({ eventName: 'portal-ready', bubbles: false, cancelable: false, composed: true })
  emitReadyEvent(): void {
    this.dispatchEvent(new CustomEvent('portal-ready', { bubbles: false, cancelable: false, composed: true }));
  }

  /**
   * Stores references to the original parent elements
   *
   * @deprecated Please use the clone option instead
   * @todo Remove once deprecated property is removed
   */
  private readonly _parentRefs = new WeakMap<Element, Element>();

  connectedCallback(): void {
    super.connectedCallback();

    // register the portal itself
    registerPortal(this.name, this);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    // clean up first
    this.removeExistingContents();

    // remove the portal from global manager
    unregisterPortal(this.name);
  }

  /**
   * Adds content nodes to the shadow DOM
   *
   * @param content - the DOM nodes to be added
   */
  showContent(content: Element): void {
    // remove eventually visible fallback contents
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.shadowRoot!.getElementById('placeholder')?.remove();
    // remove eventually existing stuff
    this.removeExistingContents();
    // store a reference to the parent element to allow restoring
    // @FIXME to be removed once deprecated property is removed
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._parentRefs.set(content, content.parentElement!);
    // clone given markup
    if (this.clone) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.shadowRoot!.innerHTML += content.outerHTML;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.shadowRoot!.append(content);
    }
  }

  /**
   * Removes all contents from the shadow DOM
   */
  resetContent(): void {
    // clear the content
    this.removeExistingContents();
    // add back fallback placeholder
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.shadowRoot!.innerHTML = '<slot id="placeholder"></slot>';
  }

  /**
   * @todo Remove as well once deprecated property is removed
   * @param changedProperties Map
   */
  protected update(changedProperties: PropertyValues<this>): void {
    super.update(changedProperties);
    if (changedProperties.has('restore')) {
      console.warn('Deprecated restore: Please use the `clone` option instead.');
    }
  }

  protected firstUpdated(changedProperties: PropertyValues<this>): void {
    super.firstUpdated(changedProperties);

    // notify about ready state
    this.emitReadyEvent();
  }

  protected removeExistingContents(): void {
    // restore "projected" contents to original contexts
    // @FIXME to be removed once deprecated property is removed
    if (this.restore) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Array.from(this.shadowRoot!.children).forEach((child) => {
        this._parentRefs.get(child)?.prepend(child);
      });
    }
    // clean content, but attach the style inline due to a bug in Firefox and Safari
    // FIXME: investigate bug and fix implementation
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.shadowRoot!.innerHTML = `<style>${Portal.styles.map(({ cssText }) => cssText).join('')}</style>`;
  }

  protected render(): TemplateResult {
    // apply level if set
    if (this.level !== undefined) {
      this.style.setProperty('--zui-portal-level', `${this.level}`);
    }
    return html`<slot id="placeholder"></slot>`;
  }
}
