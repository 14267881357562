import { Placement } from '@popperjs/core/lib/enums';
import {
  css,
  customElement,
  eventOptions,
  property,
  PropertyValues,
  query,
  queryAssignedNodes,
  state,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { html, nothing } from 'lit-html';
import { cache } from 'lit-html/directives/cache';
import { ifDefined } from 'lit-html/directives/if-defined';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { StyleInfo, styleMap } from 'lit-html/directives/style-map';

import type { EventWithCurrentTarget } from '../../../types';
import type { Checkbox } from '../../checkbox/checkbox.component';
import type { Menu } from '../../menu/menu/menu.component';
import type { MenuItem } from '../../menu/menu-item/menu-item.component';
import type { OverlayDirective } from '../../../directives/overlay/overlay.directive';
import type { SelectButton } from '../select-button/select-button.component';
import type { SelectMenu } from '../select-menu/select-menu.component';
import type { SelectPlaceholder } from '../select-placeholder/select-placeholder.component';

import { event } from '../../../decorators/event.decorator';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { FormEnabledElement, FormValidationElement } from '../../../mixins/form-participation/form-participation.types';
import { RealBaseElement } from '../../base/BaseElement';

import { SelectDivider } from '../select-divider/select-divider.component';
import { SelectOption } from '../select-option/select-option.component';

import '../select-all/select-all.component';
import '../select-menu/select-menu.component';
import '../select-placeholder/select-placeholder.component';
import '../../error-message/error-message.component';

import { getStringArrayConverter } from '../../../utils/component.utils';
import { generateUid, getContentsFromPortal } from '../../../utils/portal.utils';
import { isEqual } from 'lodash-es';

import { hostStyles } from '../../../host.styles';
import style from './select.component.scss';

type EmphasisDeprecated = 'active' | 'active-primary';
type Emphasis = 'selected' | 'selected-primary' | EmphasisDeprecated;
type MenuOverflow = 'truncate' | 'scroll';
type Size = 's' | 'l';

const SELECT_PORTAL = 'select';
const SELECT_MENU_DEFAULT_PLACEMENTS: Placement[] = ['bottom-start', 'bottom-end', 'top-start', 'top-end'];
const SELECT_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * The select feature component is a form element which allows picking one or multiple options.
 * All options and the structure are described using structural elements such as `zui-select-option` or
 * `zui-select-divider` which are declared in the default slot. Optionally a `zui-placeholder` component can be
 * reflected into the placeholder slot.
 *
 * *Please notice:* Internally, the `value` of the `zui-select` is an array of strings.
 * However, for HTML attributes only plain string is allowed.
 * If you are using the select with `multiple=false` you can pass the value of the (pre-)selected option directly.
 * When `multiple=true` you have to pass a comma-separated string as attribute, i.e. `<zui-select value="opt1,opt2">`.
 *
 * On the other hand, if you set the JS property of the component, you always have to pass an array of strings (even
 * with `multiple=false`):
 * ```js
 * const zuiSelect = document.querySelector("zui-select");
 * zuiSelect.value = ["opt1", "opt2"];
 *
 * // multiple = false
 * zuiSelect.value = ["opt2"];
 * ```
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/❖-01-Desktop---Component-Library---4.1?node-id=13009%3A2719)
 * - [Styleguide - Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/📖--Styleguide---Desktop?node-id=1%3A102386 )
 *
 * @example
 * ### Basic usage
 * ```HTML
 * <zui-select placeholder="Select an option">
 *   <zui-select-option value="foo">Foo</zui-select-option>
 *   <zui-select-option value="bar" selected>Bar</zui-select-option>
 *   <zui-select-divider>Not available</zui-select-divider>
 *   <zui-select-option value="baz" disabled>Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Using icons
 * ```HTML
 * <zui-select placeholder="Select an option">
 *   <zui-select-option value="foo">
 *     <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 *     Foo
 *   </zui-select-option>
 *   <zui-select-option value="bar" selected>Bar</zui-select-option>
 *   <zui-select-divider>Not available</zui-select-divider>
 *   <zui-select-option value="baz" disabled>
 *     <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 *     Baz
 *   </zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Multiple select
 * ```HTML
 * <zui-select multiple>
 *   <zui-select-option value="foo">Foo</zui-select-option>
 *   <zui-select-option value="bar" selected>Bar</zui-select-option>
 *   <zui-select-divider>Not available</zui-select-divider>
 *   <zui-select-option value="baz" disabled>Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Using a custom placeholder
 * ```HTML
 * <zui-select>
 *   <zui-placeholder slot="placeholder">Select an option</zui-placeholder>
 *   <zui-select-option value="foo" disabled>Foo</zui-select-option>
 *   <zui-select-option value="bar" selected>Bar</zui-select-option>
 *   <zui-select-option value="baz">Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Pre-select single value
 * ```HTML
 * <zui-select value="bar">
 *   <zui-select-option value="foo">Foo</zui-select-option>
 *   <zui-select-option value="bar">Bar</zui-select-option>
 *   <zui-select-option value="baz">Baz</zui-select-option>
 * </zui-select>
 * ```
 * ```HTML
 * <zui-select>
 *   <zui-select-option value="foo">Foo</zui-select-option>
 *   <zui-select-option value="bar" selected>Bar</zui-select-option>
 *   <zui-select-option value="baz">Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 * ### Pre-select multiple values
 * ```HTML
 * <zui-select value="foo,bar">
 *   <zui-select-option value="foo">Foo</zui-select-option>
 *   <zui-select-option value="bar">Bar</zui-select-option>
 *   <zui-select-option value="baz">Baz</zui-select-option>
 * </zui-select>
 * ```
 * ```HTML
 * <zui-select>
 *   <zui-select-option value="foo" selected>Foo</zui-select-option>
 *   <zui-select-option value="bar" selected>Bar</zui-select-option>
 *   <zui-select-option value="baz">Baz</zui-select-option>
 * </zui-select>
 * ```
 *
 * @cssprop --zui-select-animation-duration - to customize the select menu animation duration
 * @cssprop --zui-select-width - for setting a custom width to the select in any valid css length value
 * @cssprop --zui-select-menu-width - to customize the menu to have a fixed width (**must be an absolute value!**)
 *
 * @fires change - simulates the default `change` event to imitate default behavior
 * @fires input - simulates the default `input` event to imitate default behavior
 * @fires open - custom `open` event to notify about the dropdown menu being visible
 * @fires close - custom `close` event to notify about the dropdown menu being hidden
 *
 * @slot - default slot for declaring the select structure
 * @slot placeholder - allows passing-in a customized placeholder element
 *
 * @cssprop --zui-select-animation-duration - duration of the menu toggle, is passed to the portal
 * @cssprop --zui-select-width - horizontal size of the select menu, is passed to the portal
 */
@customElement('zui-select')
export class Select
  extends FormValidationMixin(FormDataHandlingMixin(RealBaseElement))
  implements FormValidationElement<FormEnabledElement> {
  static readonly styles = [hostStyles, SELECT_STYLES];

  /* eslint-disable @typescript-eslint/naming-convention */
  static readonly REQUIRED_ERROR_MESSAGE = 'Selection is required.';
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * ARIA haspopup for this element; defaults to 'listbox' if not explicitly set by author
   */
  @property({ reflect: true, attribute: 'aria-haspopup' })
  ariaHaspopup = 'listbox';

  /**
   * ARIA expanded for this element; defaults to 'false'
   */
  @property({ reflect: true, attribute: 'aria-expanded', type: String })
  ariaExpanded = 'false';

  /**
   * ARIA role for this element; defaults to 'listbox' if not explicitly set by author
   */
  @property({ reflect: true })
  role = 'listbox';

  /**
   * provides an emphasis of the select
   * The deprecated emphasis "active" and "active-primary" were renamed to "selected" and "selected-primary".
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'selected';

  /**
   * whether to hide the border or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'hide-border' })
  hideBorder = false;

  /**
   * whether to ignore outside clicks or not
   *
   * @deprecated
   */
  @property({ reflect: true, type: Boolean, attribute: 'ignore-outside-click' })
  ignoreOutsideClick = false;

  /**
   * a text based placeholder; may be overwritten by a passed-in `zui-placeholder`
   */
  @property({ reflect: true, type: String })
  placeholder = '';

  /**
   * allows to set an explicit target portal for the select menu
   */
  @property({ reflect: true, type: String })
  portal = `${SELECT_PORTAL}-${generateUid()}`;

  /**
   * An optional level to be used if the portal is created dynamically.
   */
  @property({ reflect: true, type: Number })
  level?: number;

  /**
   * the size is derived from the touch environment initially if not provided
   */
  @property({ reflect: true, type: String })
  size: Size = this.hasTouch ? 'l' : 's';

  /**
   * toggles the alternative layout
   */
  @property({ reflect: true, type: Boolean })
  alternative = false;

  /**
   * opens the dropdown menu; will also reflect its current visibility state
   */
  @property({ reflect: true, type: Boolean })
  expanded = false;

  /**
   * allows selecting multiple values using checkboxes
   */
  @property({ reflect: true, type: Boolean })
  multiple = false;

  /**
   * adds an helper item which allows toggling all menu items at once
   */
  @property({ reflect: true, type: Boolean, attribute: 'show-all-item' })
  showAllItem = false;

  /**
   * custom label for the helper item if `showAllItem` is set
   */
  @property({ reflect: true, type: String, attribute: 'all-item-label' })
  allItemLabel = 'All';

  /**
   * disables truncation and scales the select menu to its items
   */
  @property({ reflect: true, type: Boolean, attribute: 'adapt-menu-width' })
  adaptMenuWidth = false;

  /**
   * defines the overflow strategy of the select menu items
   */
  @property({ reflect: true, type: String, attribute: 'menu-overflow' })
  menuOverflow: MenuOverflow = 'truncate';

  /**
   * Allowed placements of the select menu.
   * Multiple values can be provided as space separated list.
   * The first setting will be applied initially, which defaults to `bottom-start`.
   *
   * @example `placements="bottom-start top-end"`
   * @see https://popper.js.org/docs/v2/constructors/#options
   */
  @property({ reflect: true, converter: getStringArrayConverter<Placement>(), attribute: 'menu-placements' })
  menuPlacements: Placement[] = SELECT_MENU_DEFAULT_PLACEMENTS;

  /**
   * required
   */
  @property({ reflect: true, type: Boolean })
  required = false;

  /**
   * value
   */
  @property({
    reflect: true,
    type: String,
    converter: getStringArrayConverter<string>(','),
    hasChanged(value: string[] | undefined, oldValue: string[] | undefined): boolean {
      return !isEqual(value, oldValue);
    },
  })
  value: string[] = [];

  /**
   * Simulates the default `change` event to imitate default behavior
   *
   * @private
   */
  @event({ eventName: 'change', bubbles: true, composed: false })
  emitChangeEvent(): void {
    this.dispatchEvent(new Event('change', { bubbles: true, composed: false }));
  }

  /**
   * Simulates the default `input` event to imitate default behavior
   *
   * @private
   */
  @event({ eventName: 'input', bubbles: true, composed: false })
  emitInputEvent(): void {
    this.dispatchEvent(new Event('input', { bubbles: true, composed: false }));
  }

  /**
   * Emits a custom `open` event to notify about the dropdown menu being visible
   *
   * @private
   */
  @event({ eventName: 'open', bubbles: true, composed: false })
  emitOpenEvent(): void {
    this.dispatchEvent(new CustomEvent('open', { bubbles: true, composed: false }));
  }

  /**
   * Emits a custom `close` event to notify about the dropdown menu being hidden
   *
   * @private
   */
  @event({ eventName: 'close', bubbles: true, composed: false })
  emitCloseEvent(): void {
    this.dispatchEvent(new CustomEvent('close', { bubbles: true, composed: false }));
  }

  @query('zui-select-button')
  private readonly _buttonRef: SelectButton | null;

  @queryAssignedNodes(undefined, true, 'zui-select-divider,zui-select-option')
  private readonly _optionRefs: (SelectDivider | SelectOption)[];

  private get _selectOptions(): SelectOption[] {
    return this._optionRefs?.filter(
      (option): option is SelectOption => option.tagName.toLowerCase() === 'zui-select-option'
    );
  }

  @queryAssignedNodes(undefined, true, 'zui-select-option:not([disabled])')
  private readonly _enabledOptionRefs: SelectOption[];

  @queryAssignedNodes('placeholder', false, 'zui-select-placeholder')
  private readonly _placeholderRefs: SelectPlaceholder[];

  @query('zui-select-placeholder')
  private readonly _placeholderRef: SelectPlaceholder | null;

  @query('zui-overlay-directive')
  private readonly _overlayRef: OverlayDirective | null;

  // reflects the state of the menu being completely open or currently in
  // an opening transition and not in a closed or closing condition
  @state()
  private _opened = this.expanded;

  @state()
  private _allItemState?: Checkbox['value'];

  @eventOptions({ passive: true })
  private _handleBlur(event: FocusEvent): void {
    const target = event.relatedTarget as Element | null;
    const isSelect = this.isSameNode(target);
    const isInSelect = this.contains(target);
    const isPortal = target?.tagName.toLowerCase() === 'zui-portal';
    const isMenu = this._selectMenuRef?.isSameNode(target);
    const isInMenu = this._selectMenuRef?.contains(target);
    // check blur outside
    if (!isSelect && !isInSelect && !isPortal && !isMenu && !isInMenu) {
      // close the menu
      this._toggleMenu(false);
    }
  }

  @eventOptions({ passive: true })
  private _handleButtonClick(): void {
    // check if we're allowed to do this... 🤭
    if (this.disabled || this.readonly) {
      return;
    }
    // toggle the overlay menu
    this._toggleMenu();
  }

  @eventOptions({ passive: true })
  private _handleItemClick({ currentTarget }: EventWithCurrentTarget<MenuItem>): void {
    // check if we're allowed to do this... 🤭
    if (this.disabled || currentTarget.disabled) {
      return;
    }
    // handle selection
    if (!this.multiple) {
      this._selectSingle(currentTarget.value);
    } else {
      this._selectMultiple(currentTarget.value);
    }
    this._updateAllItemState();
  }

  @eventOptions({ passive: true })
  private _handleAllClick(): void {
    // do not open menu if completely disabled
    if (this.disabled === true) {
      return;
    }

    // toggle all items at once
    this._selectAll(this._selectCount !== this._selectOptions?.filter(({ disabled }) => !disabled).length);
  }

  @eventOptions({ passive: true })
  private _handleDefaultSlotChange(): void {
    // derive the value from selected options
    this.value = this._enabledOptionRefs.filter((ref) => ref.selected).map(({ value }: SelectOption) => value);
  }

  private _selectMenuRef?: SelectMenu;

  constructor() {
    super();

    this.addValidator({
      validator: (): boolean => this.required === false || this.value?.length > 0,
      type: 'valueMissing',
      validatesOnProperties: ['required'],
    });

    this.setDefaultValidityMessages({ valueMissing: Select.REQUIRED_ERROR_MESSAGE });
  }

  private get _selectCount(): number {
    return this.value === undefined ? 0 : this.value.length;
  }

  private get _hasSelection(): boolean {
    return this._selectCount > 0;
  }

  connectedCallback(): void {
    super.connectedCallback();

    // register local event listeners
    this.addEventListener('blur', this._handleBlur);
    this.addEventListener('focus', this._handleFocus);
  }

  disconnectedCallback(): void {
    // remove local event listeners
    this.removeEventListener('blur', this._handleBlur);
    this.removeEventListener('focus', this._handleFocus);

    // I tell you we must die...
    super.disconnectedCallback();
  }

  private _reflectValueToOptions(): void {
    this._enabledOptionRefs.forEach((option) => {
      option.selected = this._hasSelection && this.value.includes(option.value);
    });
    this.requestUpdate();
  }

  private _selectAll(selected: boolean): void {
    if (selected) {
      // gather all option values
      this.value = this._enabledOptionRefs.map(({ value }: SelectOption) => value);
    } else {
      // reset values
      this.value = [];
    }
    // notify about changes
    this.emitChangeEvent();
    this.emitInputEvent();
    // retrieve all item state
    this._updateAllItemState();
  }

  private _toggleMenu(shouldOpen = !this.expanded): void {
    // nothing changed so far
    if (this._opened === shouldOpen) {
      return;
    }

    // start the opening procedure
    if (shouldOpen) {
      // set all flags to open up
      this.expanded = true;
      this._opened = true;
    } else {
      // set opened to false and wait for close event to remove it
      this._opened = false;
    }
  }

  private _updateRefs(): void {
    requestAnimationFrame(() => {
      // retrieve references to projected contents
      const [selectMenuRef] = getContentsFromPortal<SelectMenu>(this.portal, 'zui-select-menu');
      const [menuRef] = getContentsFromPortal<Menu>(this.portal, 'zui-menu');
      this._selectMenuRef = selectMenuRef;

      // update the "all" items state
      this._updateAllItemState();

      // as the menu is rendered (and will be visible soon), position again
      this._overlayRef?.forcePositioning();

      // focus the menu
      menuRef?.focus();
    });
  }

  private _removeMenu(): void {
    // will remove the select menu
    this.expanded = false;

    // focus the menubutton again
    this._buttonRef?.focus();
  }

  // in order to allow styling, we're passing the custom properties to the projection wrapper
  private _injectCustomProperties(): StyleInfo {
    const styles = getComputedStyle(this);
    const properties = ['--zui-select-animation-duration', '--zui-select-width'];

    return properties.reduce(
      (props, property) => ({
        ...props,
        [property]: styles.getPropertyValue(property),
      }),
      {}
    );
  }

  private _updateAllItemState(): void {
    // nothing selected
    if (!this._hasSelection) {
      this._allItemState = false;
    } else {
      // some or even everything selected
      this._allItemState = this._selectCount < this._selectOptions?.length ? 'mixed' : true;
    }
  }

  private _selectSingle(value: string): void {
    // store the current option only
    this.value = value !== '' ? [value] : [];

    // notify about changes
    this.emitChangeEvent();
    this.emitInputEvent();

    // close the menu
    this._toggleMenu(false);
  }

  private _selectMultiple(value: string): void {
    // toggle the selection on the stack
    if (this._hasSelection && this.value.includes(value)) {
      const index = this.value.indexOf(value);
      this.value = [...this.value.slice(0, index), ...this.value.slice(index + 1)];
    } else if (Array.isArray(this.value)) {
      this.value = [...this.value, value];
    } else {
      this.value = [value];
    }

    // notify about changes
    this.emitChangeEvent();
    this.emitInputEvent();
  }

  private _handleKeysForSelectButton({ code }: KeyboardEvent): void {
    if (this.disabled) {
      return;
    }
    switch (code) {
      case 'Enter':
      case 'Space':
      case 'ArrowUp':
      case 'ArrowDown':
        this._toggleMenu(true);
        break;
    }
  }

  private _handleKeysForMenuItems({ code }: KeyboardEvent): void {
    switch (code) {
      case 'Escape':
        this._toggleMenu(false);
        break;
    }
  }

  private _handleFocus(): void {
    // delegate focuse to button
    // TODO: use generic focus mechanism
    if (!this.disabled) {
      this._buttonRef?.focus();
    }
  }

  protected getPlaceholder(): SelectPlaceholder | null {
    // return first slotted placeholder, or the fallback content
    return this._placeholderRefs?.length > 0 ? this._placeholderRefs[0] : this._placeholderRef;
  }

  protected getDisplayValueFromPlaceholder(): string {
    const placeholder = this.getPlaceholder();
    if (placeholder !== null) {
      if (this._hasSelection) {
        return placeholder.format(
          this._selectOptions,
          this._enabledOptionRefs.filter(({ value }) => this.value.includes(value)),
          this.multiple
        );
      }
      return placeholder.innerHTML;
    }
    return this.placeholder;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  protected getPositionReference = (): SelectButton => this._buttonRef!;

  protected getMenuWidth(): string {
    const definedWidth = getComputedStyle(this).getPropertyValue('--zui-select-menu-width');
    return definedWidth !== '' ? definedWidth : `${this.offsetWidth}px`;
  }

  /**
   * Detects if one of the deprecated properties and emphasis states were used
   *
   * @param {PropertyValues} changedProperties properties which have been changed
   */
  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);

    if (changedProperties.has('ignoreOutsideClick') && this.ignoreOutsideClick) {
      console.warn(`Deprecated ignoreOutsideClick was used on zui-select.`);
    }

    if (changedProperties.has('emphasis')) {
      if (this.emphasis === 'active' || this.emphasis === 'active-primary') {
        console.warn(`Deprecated emphasis: ${this.emphasis} was used on zui-select.`);
      }
    }

    // sync ARIA attributes
    this.ariaExpanded = String(this.expanded);
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    // we only want to add logic if the value has changed
    if (changedProperties.has('value')) {
      // reflect select state to options
      this._reflectValueToOptions();
    }

    // check if initially opened
    if (changedProperties.has('expanded') && this.expanded) {
      requestAnimationFrame(() => this._toggleMenu(true));
    }
  }

  protected render(): TemplateResult {
    return html`
      <!-- trap projected contents -->
      <slot class="trap" name="placeholder">
        <zui-select-placeholder slot="placeholder">${this.placeholder}</zui-select-placeholder>
      </slot>
      <slot class="trap" @slotchange="${this._handleDefaultSlotChange}"></slot>

      <!-- build a synthetic structure from ui components -->
      <zui-select-button
        aria-describedby="menu"
        aria-expanded="${this.expanded ? 'true' : 'false'}"
        aria-haspopup="${this._selectOptions?.length ? 'menu' : 'false'}"
        role="listbox"
        ?alternative="${this.alternative}"
        ?disabled="${this.disabled}"
        ?hide-border="${this.hideBorder}"
        ?opened="${this.expanded}"
        ?readonly="${this.readonly}"
        ?selected="${this._hasSelection}"
        size="${this.size}"
        tabindex="0"
        @click="${this._handleButtonClick}"
        @keydown="${this._handleKeysForSelectButton}"
      >
        ${unsafeHTML(this.getDisplayValueFromPlaceholder())}
      </zui-select-button>

      ${this.expanded
        ? cache(html`
            <zui-overlay-directive
              flip
              level="${ifDefined(this.level)}"
              portal="${this.portal}"
              .placements="${this.menuPlacements}"
              .positionReferenceCallback="${this.getPositionReference}"
            >
              <zui-select-menu
                ?open="${this._opened}"
                style="${styleMap(this._injectCustomProperties())}"
                @select-menu-open="${this._updateRefs}"
                @select-menu-closed="${this._removeMenu}"
              >
                <zui-menu
                  id="menu"
                  scrollable-background="visible"
                  size="${this.size}"
                  overflow="${this.menuOverflow}"
                  tabindex="${this.expanded ? 0 : -1}"
                  ?adapt-width="${this.adaptMenuWidth}"
                  ?disabled="${this.disabled}"
                  style="${styleMap({
                    '--zui-menu-margin-top': this.hideBorder ? '4px' : '0',
                    '--zui-menu-width': this.getMenuWidth(),
                  })}"
                  @blur="${this._handleBlur}"
                  @keydown="${this._handleKeysForMenuItems}"
                >
                  ${this.multiple && this.showAllItem
                    ? html`
                        <zui-select-all
                          value="${this._allItemState}"
                          @blur="${this._handleBlur}"
                          @click="${this._handleAllClick}"
                        >
                          ${this.allItemLabel}
                        </zui-select-all>
                      `
                    : nothing}
                  ${this._optionRefs?.map((item) => {
                    if (item instanceof SelectDivider) {
                      return html`<zui-menu-divider>${unsafeHTML(item.innerHTML)}</zui-menu-divider>`;
                    }
                    if (item instanceof SelectOption) {
                      return html`
                        <zui-menu-item
                          role="option"
                          emphasis="${!this.multiple && item.selected ? this.emphasis : 'default'}"
                          aria-selected="${item.selected}"
                          ?selectable="${this.multiple}"
                          ?selected="${item.selected}"
                          ?disabled="${this.disabled || item.disabled}"
                          value="${item.value}"
                          @blur="${this._handleBlur}"
                          @click="${this._handleItemClick}"
                        >
                          ${unsafeHTML(item.innerHTML)}
                        </zui-menu-item>
                      `;
                    }
                    return nothing;
                  })}
                </zui-menu>
              </zui-select-menu>
            </zui-overlay-directive>
          `)
        : nothing}
      ${this.invalid && this.willValidate
        ? html`<zui-error-message>${this.validationMessage}</zui-error-message>`
        : nothing}
    `;
  }
}
