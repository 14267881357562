import { CSSResultArray, TemplateResult, customElement, html, property } from 'lit-element';
import { css, unsafeCSS } from 'lit-element/lib/css-tag';
import { RealBaseElement } from '../../base/BaseElement';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { FormEnabledElement, FormValidationElement } from '../../../mixins/form-participation/form-participation.types';
import { getDefaultLocale, isoDateConverter } from '../../date-picker/utils/date-picker.utils';
import {
  diffTime,
  getDayTime,
  getUpdatedDate,
  hasDayTime,
  timePickerInputValueConverter,
} from '../utils/time-picker.utils';
import { query, state } from 'lit-element/lib/decorators';
import { ifDefined } from 'lit-html/directives/if-defined';
import { generateUid } from '../../../utils/portal.utils';
import { Placement } from '@popperjs/core/lib/enums';
import { nothing } from 'lit-html/lib/part';
import { DateTime } from 'luxon';
import { isDefined } from '../../../utils/component.utils';
import { EventWithTarget } from '../../../types';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { event } from '../../../decorators/event.decorator';
import { until } from 'lit-html/directives/until';

import { OverlayDirective } from '../../../directives/overlay/overlay.directive';
import { TimePicker } from '../time-picker/time-picker.component';
import { TimePickerDayTimeToggle } from '../time-picker-day-time-toggle/time-picker-day-time-toggle.component';
import { TimePickerInput } from '../time-picker-input/time-picker-input.component';
import '../../popover/popover.component';
import '../../tooltip/tooltip.component';

import { hostStyles } from '../../../host.styles';
import styles from './textfield-time-picker.component.scss';

const textfieldTimePickerComponentStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The `zui-textfield-time-picker` is a form enabled component. It uses the `zui-time-picker-input` for entering hour and minute values and when in `h12` mode the day time.
 * The `zui-time-picker` is shown on the `zui-time-picker-input` icon click and can also be used for changing the time.
 * When no `locale` is given it uses the browser locale. When no `hour-cycle` is given the locale based `hour-cycle` is used.
 * It is possible to set a min/max date time to show error messages accordingly when the entered time is lower or greater.
 *
 * @example
 * html```
 * <zui-textfield-time-picker></zui-textfield-time-picker>
 * ```
 *
 * @fires CustomEvent - the `textfield-time-picker-input-changed` event is emitted when the input value has changed
 * @fires change - the `change` event is emitted on change
 * @fires input - the `input` event is emitted on input
 *
 * @cssprop --zui-textfield-time-picker-input-hour-placeholder-width - width of the hour input when no value is present - default: 20px
 * @cssprop --zui-textfield-time-picker-input-minute-placeholder-width - width of the minute input when no value is present - default: 24px
 * @cssprop --zui-textfield-time-picker-input-width - width of the input - default: 100%
 */
@customElement('zui-textfield-time-picker')
export class TextfieldTimePicker
  extends FormValidationMixin(FormDataHandlingMixin(RealBaseElement))
  implements FormValidationElement<FormEnabledElement> {
  static get styles(): CSSResultArray {
    return [hostStyles, textfieldTimePickerComponentStyles];
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  private static readonly FORMAT_TIME_H12 = 'hh:mm a';
  private static readonly FORMAT_TIME_H23 = 'HH:mm';
  private static readonly INVALID_WARNING_DELAY = 500;
  private static readonly MAX_ERROR_MESSAGE = 'This time is not allowed.';
  private static readonly MIN_ERROR_MESSAGE = 'This time is not allowed.';
  private static readonly REQUIRED_ERROR_MESSAGE = 'The value is required.';
  private static readonly TIME_PICKER_PLACEMENTS: Placement[] = ['bottom-end', 'bottom-start'];
  private static readonly WARNING_MESSAGE_PLACEMENTS: Placement[] = ['bottom-start'];
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * the default value is used for the time picker popover when there is no value or just parts of the `zui-time-picker-input`.
   */
  @property({ reflect: true, type: String, attribute: 'default-value', converter: isoDateConverter })
  defaultValue: Date = DateTime.now().set({ second: 0, millisecond: 0 }).toJSDate();

  private get _defaultValueDT(): DateTime {
    return DateTime.fromJSDate(this.defaultValue);
  }

  /**
   * hour cycle
   *
   * @returns {'h12' | 'h23'} locale dependent or overridden hour cycle
   */
  @property({ reflect: true, type: String, attribute: 'hour-cycle' })
  get hourCycle(): TimePicker['hourCycle'] {
    if (!this._internalHourCycle) {
      return hasDayTime(this.locale) ? 'h12' : 'h23';
    }

    return this._internalHourCycle;
  }

  set hourCycle(value: TimePicker['hourCycle']) {
    const oldValue = this._internalHourCycle;
    this._internalHourCycle = value;

    this.requestUpdate('hourCycle', oldValue);
  }

  // todo: remove when a reusable solution is implemented
  /**
   * @private
   */
  @property({ reflect: true, type: Number })
  level = 1000;

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * max value
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  max: Date;

  private get _maxValueDT(): DateTime | undefined {
    return this.max ? DateTime.fromJSDate(this.max) : undefined;
  }

  /**
   * min value
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  min: Date;

  private get _minValueDT(): DateTime | undefined {
    return this.min ? DateTime.fromJSDate(this.min) : undefined;
  }

  /**
   * placeholder day time
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-day-time' })
  placeholderDayTime = 'AM';

  /**
   * placeholder hour
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-hour' })
  placeholderHour = 'HH';

  /**
   * placeholder minute
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-minute' })
  placeholderMinute = 'MM';

  /**
   * required
   */
  @property({ reflect: true, type: Boolean })
  required = false;

  /**
   * selected value
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  value: Date | null = null;

  private get _valueDT(): DateTime | undefined {
    return this.value ? DateTime.fromJSDate(this.value) : undefined;
  }

  /**
   * @private
   */
  @event({
    eventName: 'textfield-time-picker-input-changed',
    bubbles: true,
    composed: true,
  })
  emitTextfieldTimePickerInputChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('textfield-time-picker-input-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: this._valueDT?.toISO() ?? null,
        },
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new InputEvent('input', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'change',
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  @query('zui-overlay-directive#textfield-time-picker')
  private _textfieldTimePickerOverlayRef: OverlayDirective;

  @query('zui-time-picker-input')
  private _timePickerInputRef: TimePickerInput;

  @state()
  private _showTimePicker = false;

  @state()
  private _internalHourCycle: TimePicker['hourCycle'];

  @state()
  private _timePickerInputValue: Partial<TimePickerInput['value']>;

  private _userChange = false;
  private readonly _timePickerPortal = `time-picker-${generateUid()}`;
  private readonly _warningMessagePortal = `time-picker-warning-message-${generateUid()}`;

  private _textfieldTimePickerResizeObserver = new ResizeObserver(() => {
    requestAnimationFrame(() => {
      this._textfieldTimePickerOverlayRef?.forcePositioning();
    });
  });

  private get _currentValueDT(): DateTime {
    return this._valueDT ?? this._defaultValueDT;
  }

  private get _dayTime(): TimePickerDayTimeToggle['value'] | undefined {
    return this._is12HourFormat ? getDayTime(this._currentValueDT, this.hourCycle) : undefined;
  }

  private get _formattedValue(): string | undefined {
    if (this._valueDT) {
      return this._valueDT.toFormat(this._timeFormat);
    }

    return timePickerInputValueConverter.toAttribute(this._timePickerInputValue) as string;
  }

  private get _is12HourFormat(): boolean {
    return this.hourCycle === 'h12';
  }

  private get _showInvalidWarning(): boolean {
    return this.willValidate && this.invalid && !this._showTimePicker;
  }

  private get _timeFormat(): string {
    return this.hourCycle === 'h12' ? TextfieldTimePicker.FORMAT_TIME_H12 : TextfieldTimePicker.FORMAT_TIME_H23;
  }

  constructor() {
    super();
    // setup form integration
    this.addValidator({ type: 'rangeOverflow', validator: this._maxValidator, validatesOnProperties: ['max'] });
    this.addValidator({ type: 'rangeUnderflow', validator: this._minValidator, validatesOnProperties: ['min'] });
    this.addValidator({
      type: 'valueMissing',
      validator: this._requiredValidator,
      validatesOnProperties: ['required'],
    });

    this.setDefaultValidityMessages({
      rangeOverflow: TextfieldTimePicker.MAX_ERROR_MESSAGE,
      rangeUnderflow: TextfieldTimePicker.MIN_ERROR_MESSAGE,
      valueMissing: TextfieldTimePicker.REQUIRED_ERROR_MESSAGE,
    });
  }

  connectedCallback(): void {
    super.connectedCallback();

    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.addEventListener('click', this._handleOutsideClick);
    window.addEventListener('keydown', this._handleKeydown);
  }

  disconnectedCallback(): void {
    this._textfieldTimePickerResizeObserver.unobserve(this._timePickerInputRef);

    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.removeEventListener('click', this._handleOutsideClick);
    window.removeEventListener('keydown', this._handleKeydown);

    super.disconnectedCallback();
  }

  formResetCallback(): void {
    super.formResetCallback();

    this._timePickerInputValue = DateTime.fromISO(this.resetValue as string).isValid
      ? timePickerInputValueConverter.fromAttribute(this.resetValue as string)
      : {
          dayTime: undefined,
          hour: undefined,
          minute: undefined,
        };
  }

  private _handleKeydown = ({ code }: KeyboardEvent): void => {
    if (this._showTimePicker && code === 'Escape') {
      this._showTimePicker = false;
    }
  };

  // todo: this should be removed when a reusable solution has been implemented
  // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
  private _handleOutsideClick = (event: EventWithTarget<TextfieldTimePicker | Element>): void => {
    if (!this._showTimePicker) {
      return;
    }

    const isInsideClick =
      this.isSameNode(event.target) && event.composedPath().some((path) => path instanceof TimePickerInput);
    const isTimePickerPortal = event.target.getAttribute('name') === this._timePickerPortal;

    if (!isInsideClick && !isTimePickerPortal) {
      this._showTimePicker = false;
    }
  };

  private _maxValidator = (): boolean =>
    isDefined(this._maxValueDT) && isDefined(this._valueDT) ? diffTime(this._valueDT, this._maxValueDT) >= 0 : true;
  private _minValidator = (): boolean =>
    isDefined(this._minValueDT) && isDefined(this._valueDT) ? diffTime(this._valueDT, this._minValueDT) <= 0 : true;
  private _offset = (): number[] => [0, 8];
  private _requiredValidator = (): boolean => this.required === false || isDefined(this.value);
  private _timePickerPositionReference = (): TimePickerInput => this._timePickerInputRef;

  private async _getDelayedInvalidWarningOrNothing(): Promise<TemplateResult | typeof nothing> {
    if (this._showInvalidWarning) {
      // we wait for a promise that resolves after the timeout if the component is then still invalid; if not we wait forever
      await new Promise<void>((resolve) =>
        setTimeout(() => {
          if (this._showInvalidWarning) {
            resolve();
          }
        }, TextfieldTimePicker.INVALID_WARNING_DELAY)
      );

      return html`
        <zui-overlay-directive
          .offsetHandler="${this._offset}"
          .placements="${TextfieldTimePicker.WARNING_MESSAGE_PLACEMENTS}"
          .positionReferenceCallback="${this._timePickerPositionReference}"
          level="${this.level}"
          portal="${this._warningMessagePortal}"
        >
          <zui-tooltip emphasis="warning">${this.validationMessage}</zui-tooltip>
        </zui-overlay-directive>
      `;
    }

    return nothing;
  }

  private _handleTimePickerChangedEvent({ detail }: CustomEvent<{ value: string }>): void {
    this._userChange = true;
    this.value = new Date(detail.value);
  }

  private _handleTimePickerInputEvent(): void {
    this.emitInputEvent();
  }

  private _handleTimePickerInputInputEvent(): void {
    this.emitInputEvent();
  }

  private _handleTimePickerInputChangedEvent({ detail }: CustomEvent<{ value: string | undefined }>): void {
    this._timePickerInputValue = { ...this._timePickerInputRef.value };

    this._userChange = true;
    // map from undefined to null, otherwise create date
    this.value = isDefined(detail.value) ? new Date(detail.value) : null;
  }

  private _handleTimePickerInputFocusInEvent(): void {
    if (this._showTimePicker) {
      this._showTimePicker = false;
    }
  }

  private _handleTimePickerInputToggle(): void {
    this._showTimePicker = !this._showTimePicker;

    if (!this._showTimePicker) {
      return;
    }

    requestAnimationFrame(() => {
      this._textfieldTimePickerOverlayRef?.forcePositioning();
    });

    // only update value on open when there is no current value
    if (isDefined(this.value)) {
      return;
    }

    // set value on open to a mix of input parts and default value
    const { dayTime, hour, minute } = this._timePickerInputRef.value ?? {
      dayTime: undefined,
      hour: undefined,
      minute: undefined,
    };
    const updateValue = this._is12HourFormat
      ? isDefined(dayTime) || isDefined(hour) || isDefined(minute)
      : isDefined(hour) || isDefined(minute);

    if (updateValue) {
      // treat open as a user change as the value will be updated
      this._userChange = true;

      this.value = getUpdatedDate(
        {
          hour: hour ?? this._defaultValueDT?.hour,
          minute: minute ?? this._defaultValueDT?.minute,
        },
        this._defaultValueDT,
        this.hourCycle,
        // FIXME: this looks like a bug
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (dayTime ?? this._dayTime)!
      ).toJSDate();

      this.emitInputEvent();
    }
  }

  protected firstUpdated(changedProperties: PropertyValues): void {
    super.firstUpdated(changedProperties);

    this._textfieldTimePickerResizeObserver.observe(this._timePickerInputRef);
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('value') && this._userChange) {
      this.emitChangeEvent();
      this.emitTextfieldTimePickerInputChangedEvent();
      this._userChange = false;
    }
  }

  protected render(): TemplateResult {
    return html`
      <zui-time-picker-input
        zuiFormControl
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        ?invalid="${this.willValidate && this.invalid}"
        hour-cycle="${this.hourCycle}"
        placeholder-day-time="${this.placeholderDayTime}"
        placeholder-hour="${this.placeholderHour}"
        placeholder-minute="${this.placeholderMinute}"
        default-value="${this._defaultValueDT.toFormat('HH:mm')}"
        value="${ifDefined(this._formattedValue)}"
        @focusin="${this._handleTimePickerInputFocusInEvent}"
        @time-picker-input-changed="${this._handleTimePickerInputChangedEvent}"
        @time-picker-input-input="${this._handleTimePickerInputInputEvent}"
        @time-picker-input-toggle="${this._handleTimePickerInputToggle}"
      >
      </zui-time-picker-input>

      ${until(this._getDelayedInvalidWarningOrNothing())}
      ${this._showTimePicker
        ? html`
            <zui-overlay-directive
              .offsetHandler="${this._offset}"
              .placements="${TextfieldTimePicker.TIME_PICKER_PLACEMENTS}"
              .positionReferenceCallback="${this._timePickerPositionReference}"
              flip
              id="textfield-time-picker"
              level="${this.level}"
              portal="${this._timePickerPortal}"
            >
              <zui-popover style="width: auto; min-width: auto; padding: 32px">
                <zui-time-picker
                  ?disabled="${this.disabled}"
                  default-value="${this._defaultValueDT}"
                  hour-cycle="${this.hourCycle}"
                  locale="${this.locale}"
                  value="${ifDefined(this._valueDT)}"
                  @time-picker-changed="${this._handleTimePickerChangedEvent}"
                  @time-picker-input="${this._handleTimePickerInputEvent}"
                ></zui-time-picker>
              </zui-popover>
            </zui-overlay-directive>
          `
        : nothing}
    `;
  }
}
